import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import HubspotForm from 'react-hubspot-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import { withAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

var api = axios.create({ withCredentials: true });

polyfill();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Landing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false
        };
    }

    /////////////////////

    handleModal(value) {
        this.setState({ modal: value });
    }

    render() {
        return (
            <div data-theme-mode-panel-active data-theme="light" style={{ fontFamily: 'Mazzard H' }}>
                <div class="site-wrapper overflow-hidden position-relative">
                    <header
                        class="site-header site-header--menu-center dark-mode-texts landing-17-menu  site-header--absolute site-header--sticky">
                        <div class="container">
                            <nav class="navbar site-navbar">
                                <div class="brand-logo">
                                    <a href="#">
                                        <img src="/landing/image/logo/logo-white.png" alt="" class="light-version-logo" />

                                        <img src="/landing/image/logo/logo-white.png" alt="" class="dark-version-logo" />
                                    </a>
                                </div>
                                <div class="header-btns header-btn-l-17 ms-auto d-xs-inline-flex align-items-center">
                                    <span class="btn sign-in-btn focus-reset" onClick={() => { this.props.auth0.loginWithRedirect(); }}>
                                        Customer Login
                                    </span>
                                    <a class="start-trail-btn btn focus-reset" onClick={() => { this.handleModal(true); }}>
                                        Get Started
                                    </a>
                                </div>
                            </nav>
                        </div>
                    </header>


                    <div class="hero-area-l-17 position-relative">
                        <div class="container">
                            <div class="row position-relative justify-content-center">
                                <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-11 order-lg-1 order-1" data-aos="fade-right"
                                    data-aos-duration="800" data-aos-once="true">
                                    <div class="content text-center">
                                        <h1>Diagnostics for your Repair Shop</h1>
                                        <p>A revolution in the way auto repair shops like yours gather and use data has finally begun.</p>
                                        <a onClick={() => { this.handleModal(true); }} class="btn">Get Started</a>
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-9 order-lg-1 order-0">
                                    <div class="hero-area-image">
                                        <img src="/landing/image/l8/hero-img.png" alt="" class="w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="feature-area-l-17 position-relative">
                        <div class="container">
                            <div class="row feature-area-l-17-items justify-content-center text-center">
                                <div class="col-lg-4 col-md-6 col-sm-9">
                                    <div class="single-features single-border position-relative">
                                        <img src='/landing/image/l8/target_icon.png' style={{ maxHeight: '75px', marginBottom: '35px' }} />
                                        <h4>Target Based Data</h4>
                                        <p>Set your own metric targets to drive your dashboards and gain trending insights.</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-9">
                                    <div class="single-features single-border position-relative">
                                        <img src='/landing/image/l8/integration_icon.png' style={{ maxHeight: '75px', marginBottom: '35px' }} />
                                        <h4>Seamless Integration</h4>
                                        <p>No weeks long setup process required. Changepoint Automotive can integrate with your shop in under 24 hours.</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-9">
                                    <div class="single-features">
                                        <img src='/landing/image/l8/customer_icon.png' style={{ maxHeight: '75px', marginBottom: '35px' }} />
                                        <h4>Customer Ratings</h4>
                                        <p>Every customer is rated across multiple metrics to help drive your workflow and save you time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-area-l-17-1">
                        <div class="container">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-xl-10 col-lg-12">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-lg-6 col-md-8" data-aos="fade-right" data-aos-duration="800"
                                            data-aos-once="true">
                                            <div class="content-img position-relative">
                                                <div class="image-1">
                                                    <img src="/landing/image/l8/content-image-2.png" alt="" style={{ maxWidth: '400px' }} />
                                                </div>
                                                <div class="image-2">
                                                    <img src="/landing/image/l8/content-image-1.png" alt="" style={{ maxWidth: '350px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="offset-xxl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-8" data-aos="fade-left"
                                            data-aos-duration="800" data-aos-once="true">
                                            <div class="content section-heading-11">
                                                <h2>Data trends that help you optimize your operations.</h2>
                                                <p>With the newly created Shop Trends Pro software you can see all of your data, measured against any KPI, in just seconds. This is the first and only reporting software of its kind.</p>
                                                <a onClick={() => { this.handleModal(true); }} class="btn focus-reset">Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="video-area-l-17">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-xxl-8 col-xl-8 col-lg-9 col-md-12">
                                    <div class="video-content text-center">
                                        <img src="/landing/image/l8/ringcentral.png" alt="" />
                                        <h2>RingCentral Integration</h2>
                                        <p>Our innovative Service Advisor Assistant software that helps you keeps track of your customers.<br />When they call, it pulls up their information before your advisor even answers the phone.<br />Works across all browsers on both Windows & Mac.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-area-l-17-2">
                        <div class="container">
                            <div class="row align-items-center justify-content-center">
                                <div class="col-xl-10 col-lg-12">
                                    <div class="row align-items-center justify-content-center">
                                        <div class="col-xxl-5 col-xl-6 col-lg-6 col-md-8 order-lg-1 order-1" data-aos="fade-left"
                                            data-aos-duration="800" data-aos-once="true">
                                            <div class="content section-heading-11">
                                                <h2>History driven customer insights.</h2>
                                                <p>Quickly determine customer quality based on RO history and sales. See services dates and RO details.</p>
                                                <a onClick={() => { this.handleModal(true); }} class="btn focus-reset">Get Started</a>
                                            </div>
                                        </div>
                                        <div class="offset-xxl-1 col-xxl-6 col-xl-6 col-lg-6 col-md-8 order-lg-1 order-0"
                                            data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                                            <div class="content-img position-relative">
                                                <div class="image-1">
                                                    <img src="/landing/image/l8/content-image-3.png" alt="" style={{ maxWidth: '400px' }} />
                                                </div>
                                                <div class="image-2">
                                                    <img src="/landing/image/l8/content-image-4.png" alt="" style={{ maxWidth: '350px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="brand-area-l-17">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-xl-9 col-lg-11 col-md-12">
                                    <div class="content text-center">
                                        <p>We integrate with your current shop management system</p>
                                    </div>
                                    <div
                                        class="brand-area-l-17-items d-flex justify-content-center justify-content-xl-between align-items-center flex-wrap ">
                                        <div class="single-brand " data-aos="fade-right" data-aos-duration="500"
                                            data-aos-once="true">
                                        </div>
                                        <div class="single-brand " data-aos="fade-right" data-aos-duration="700"
                                            data-aos-once="true">
                                            <img src="/landing/image/l8/tekmetric.png" alt="" style={{ maxHeight: '50px' }} />
                                        </div>
                                        <div class="single-brand " data-aos="fade-right" data-aos-duration="900"
                                            data-aos-once="true">
                                            <img src="/landing/image/l8/shop-ware.png" alt="" style={{ maxHeight: '50px' }} />
                                        </div>
                                        <div class="single-brand " data-aos="fade-right" data-aos-duration="1100"
                                            data-aos-once="true">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer class="footer-area-l-17 position-relative">
                        <div class="footer-shape">
                            <img src="/landing/image/l8/footer-shape.svg" alt="" />
                        </div>
                        <div class="container pt-lg-23 pt-15 pb-12">
                            <div class="row footer-area-l-17-items justify-content-between" data-aos="fade-left"
                                data-aos-duration="800" data-aos-once="true">
                            </div>
                        </div>

                        <div class="copyright-area-l-17 text-center text-md-start">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-4 col-md-4">
                                        <div class="copyright">
                                            <p> &copy; ChangePoint Automotive {new Date().getFullYear()} All right reserved. </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-6">
                                        <div class="footer-menu">
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-2">
                                        <div class="social-icons text-md-end">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={this.state.modal}
                    onClose={() => { this.handleModal(false); }}
                    TransitionComponent={Transition}
                >
                    <DialogTitle>Get Started - Contact Us</DialogTitle>
                    <DialogContent>
                        <HubspotForm
                            portalId='22807039'
                            formId='1f0ab2e8-51be-4416-b917-eedff9bbb157'
                            loading={
                                <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                                    <CircularProgress size={60} thickness={4} />
                                </Box>
                            }
                            onFormSubmitted={() => {
                                this.handleModal(false);
                                toast.success('Contact Successful!');
                            }}
                            inlineMessage='Thank You for Contacting Us!'
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.handleModal(false); }}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withAuth0(Landing);
