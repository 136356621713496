import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

var api = axios.create({ withCredentials: true });

polyfill();

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D1/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
};

class SetupRC extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jwt: null, queues: [], show: false
        };
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    toggle() {
        this.setState({ show: !this.state.show });
    }

    checkQueues(q) {
        if (this.props.shop.hasOwnProperty('rcQueues')) {
            if (this.props.shop.rcQueues.includes(q)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /////////////////////

    getCurrent() {
        if (this.props.shop.hasOwnProperty('rcJWT')) {
            this.setState({ jwt: this.props.shop.rcJWT }, () => { this.getQueues(); });
        } else {
            this.setState({ jwt: null, queues: [] });
        }
    }

    /////////////////////

    setJWT() {
        if (this.state.jwt.trim() === '' || this.state.jwt === null) {
            toast.error('JWT cannot be empty');
        } else {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/companies/update/jwt/' + this.props.shop._id,
                    { jwt: this.state.jwt }, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                        toast.success('Successfully updated RingCentral JWT');
                    }).catch((cerror) => {
                        toast.error(cerror.response.data);
                    });
            }).catch((token_error) => {
                console.log(token_error);
            });
        }
    }

    /////////////////////

    deleteJWT() {
        if (this.state.jwt.trim() === '' || this.state.jwt === null) {
            toast.error('No JWT to delete');
        } else {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.get(
                    process.env.REACT_APP_BASEURL + 'api/companies/delete/jwt/' + this.props.shop._id,
                    { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                        toast.success('Successfully deleted RingCentral JWT');
                    }).catch((cerror) => {
                        toast.error(cerror.response.data);
                    });
            }).catch((token_error) => {
                console.log(token_error);
            });
        }
    }

    /////////////////////

    getQueues() {
        if (this.state.jwt.trim() === '' || this.state.jwt === null) {
            this.setState({ queues: [] });
        } else {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/companies/queues/jwt/' + this.props.shop._id,
                    { jwt: this.state.jwt }, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                        this.setState({ queues: response.data });
                    }).catch((cerror) => {
                        toast.error(cerror.response.data);
                    });
            }).catch((token_error) => {
                console.log(token_error);
            });
        }
    }

    /////////////////////

    updateQueues(q) {
        var current = [];
        if (this.props.shop.hasOwnProperty('rcQueues')) {
            this.props.shop.rcQueues.forEach((c) => {
                current.push(c);
            });
        }
        var index = current.indexOf(q);
        if (index !== -1) {
            current = current.filter((x) => { return x !== q })
        } else {
            current.push(q);
        }

        if (this.state.jwt.trim() === '' || this.state.jwt === null) {
            toast.error('JWT cannot be empty');
        } else {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/companies/updatequeues/jwt/' + this.props.shop._id,
                    { queues: current }, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                        toast.success('Successfully updated RingCentral Queues');
                    }).catch((cerror) => {
                        toast.error(cerror.response.data);
                    });
            }).catch((token_error) => {
                console.log(token_error);
            });
        }
    }

    /////////////////////

    displayQueues() {
        if (this.state.queues.length > 0) {
            return this.state.queues.map((q) => {
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.checkQueues('' + q.extension.id)}
                                onChange={() => { this.updateQueues('' + q.extension.id); }}
                            />
                        }
                        label={formatPhoneNumber(q.phoneNumber) + " (" + q.location + ")"}
                    />
                );
            });
        }
    }

    /////////////////////

    componentDidMount() {
        this.getCurrent();
    }

    componentDidUpdate(prevProps) {
        var reload = false;
        if (prevProps.shop._id === this.props.shop._id) {
            if (prevProps.shop.hasOwnProperty('rcJWT') && this.props.shop.hasOwnProperty('rcJWT')) {
                if (prevProps.shop.rcJWT !== this.props.shop.rcJWT) {
                    reload = true;
                }
            } else if (this.props.shop.hasOwnProperty('rcJWT') || prevProps.shop.hasOwnProperty('rcJWT')) {
                reload = true;
            }
        } else {
            reload = true;
        }

        if (reload) {
            this.getCurrent();
        }
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            return (
                <Box flexGrow={1}>
                    <Typography variant='h6' gutterBottom color='primary'>Set RingCentral JWT</Typography>
                    <Divider flexItem sx={{ mb: 2 }} />
                    <Button variant="contained" color='primary' onClick={() => { this.toggle(); }}>
                        Show/Hide RingCentral JWT Instructions
                    </Button>
                    <Paper elevation={2} flexGrow={1} sx={this.state.show ? { display: 'block', mt: 2, p: 2 } : { display: 'none' }}>
                        <Typography variant='h6' gutterBottom>Step 1. Go to your Developer Portal from the RingCentral Admin Portal</Typography>
                        <img src='images/rc_step1.png' style={{ maxWidth: '75%', border: '1px solid grey' }} /><br /><br />
                        <Typography variant='h6' gutterBottom>Step 2. At the top right, select your name and then Credentials</Typography>
                        <img src='images/rc_step2.png' style={{ maxWidth: '75%', border: '1px solid grey' }} /><br /><br />
                        <Typography variant='h6' gutterBottom>Step 3. Select Create JWT</Typography>
                        <img src='images/rc_step3.png' style={{ maxWidth: '75%', border: '1px solid grey' }} /><br /><br />
                        <Typography variant='h6' gutterBottom>Step 4. Name it whatever you like (we suggest ChangePointAuto)</Typography>
                        <Typography variant='h6' gutterBottom>Step 5. Select the 'Production' environment</Typography>
                        <Typography variant='h6' gutterBottom>Step 6. Check 'Only specific apps of my choice'</Typography>
                        <Typography variant='h6' gutterBottom>Step 7. Enter the ChangePointAuto ClientID : <i><b>x-F1aHA_SGyt9paFnjsz0w</b></i></Typography>
                        <Typography variant='h6' gutterBottom>Step 8. Click <b>Add App</b>. After it appears as below, click Create JWT</Typography>
                        <img src='images/rc_step4.png' style={{ maxWidth: '75%', border: '1px solid grey' }} /><br /><br />
                        <Typography variant='h6' gutterBottom>Step 9. Select the created JWT, copy the value (very long value) and paste it below!</Typography>
                        <img src='images/rc_step5.png' style={{ maxWidth: '75%', border: '1px solid grey' }} />
                    </Paper>
                    <Divider flexItem sx={{ mb: 2, mt: 2 }} />
                    <Typography variant='caption' gutterBottom>ChangePointAuto RingCentral ClientID : x-F1aHA_SGyt9paFnjsz0w</Typography>
                    <TextField
                        id="avgro-basic"
                        label="Your JWT"
                        variant="standard"
                        onChange={this.handleInputChange}
                        name='jwt'
                        value={this.state.jwt ? this.state.jwt : ""}
                        sx={{ width: '100%', mt: 2 }}
                    /><br /><br />
                    <Button variant="contained" endIcon={<SaveRoundedIcon />} color='success' onClick={() => { this.setJWT(); }}>
                        Save JWT
                    </Button>
                    <Button variant="contained" endIcon={<DeleteForeverRoundedIcon />} color='error' onClick={() => { this.deleteJWT(); }} sx={{ ml: "5px" }}>
                        Remove JWT
                    </Button>
                    {
                        this.state.queues.length > 0
                            ?
                            <>
                                <br /><br /><br />
                                <Typography variant='h6' gutterBottom color='primary'>Select Shop Specific Phone Number(s)</Typography>
                                <Typography gutterBottom><b>This section is for multi-shop clients who use a single RingCentral account for all locations.</b></Typography>
                                <Typography gutterBottom>(If none are selected, all RingCentral account numbers are used)</Typography>
                                <Divider flexItem sx={{ mb: 2, mt: 2 }} />
                                {this.displayQueues()}
                            </>
                            :
                            <></>
                    }
                </Box>
            );
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(SetupRC);
