import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'pace-js';
import 'pace-js/themes/blue/pace-theme-minimal.css';
import { Auth0Provider } from "@auth0/auth0-react";

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('6b9261fa58ab0e9ac605b2ead442c30eTz02MTczOCxFPTE3MTAwNzY4NzY4NDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0DOMAIN}
    clientId={process.env.REACT_APP_AUTH0CLIENTID}
    redirectUri={process.env.REACT_APP_BASEURL}
    audience={process.env.REACT_APP_AUTH0AUDIENCE}
    scope={process.env.REACT_APP_AUTH0SCOPE}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
