import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

import SetupMetrics from './metrics.metrics.com';
import SetupDates from './metrics.dates.com';
import SetupRC from './metrics.rc.com';
import SetupJobs from './metrics.jobs.com';
import SetupGoals from './metrics.goals.com';

var api = axios.create({ withCredentials: true });

polyfill();

class Metrics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "1"
        };
    }

    handleChange = (event, value) => {
        this.setState({ value: value });
    };

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            return (
                <Paper elevation={5}>
                    <TabContext value={this.state.value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={this.handleChange}>
                                <Tab label="Reporting Metrics" value="1" />
                                <Tab label="Reporting Job Map" value="2" />
                                <Tab label="Maintenance Goals" value="3" />
                                <Tab label="Operating Schedule" value="4" />
                                {
                                    this.props.loggedInUser.advisor
                                        ?
                                        <Tab label="RingCentral Integration" value="5" />
                                        :
                                        <></>
                                }
                            </TabList>
                        </Box>
                        <TabPanel value="1"><SetupMetrics shop={this.props.shop} socket={this.props.socket} /></TabPanel>
                        <TabPanel value="2"><SetupJobs shop={this.props.shop} socket={this.props.socket} /></TabPanel>
                        <TabPanel value="3"><SetupGoals shop={this.props.shop} socket={this.props.socket} /></TabPanel>
                        <TabPanel value="4"><SetupDates shop={this.props.shop} socket={this.props.socket} /></TabPanel>
                        {
                            this.props.loggedInUser.advisor
                                ?
                                <TabPanel value="5"><SetupRC shop={this.props.shop} socket={this.props.socket} /></TabPanel>
                                :
                                <></>
                        }
                    </TabContext>
                </Paper>
            );
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(Metrics);
