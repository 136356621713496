import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import BackupTableRoundedIcon from '@mui/icons-material/BackupTableRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import { withAuth0 } from '@auth0/auth0-react';
import CryptoJS from 'crypto-js';

import Scheduler from './scheduler.com';

polyfill();

function createToken(userName, kid, secret, iss, scp) {
    var header = {
        "alg": "HS256",
        "typ": "JWT",
        "iss": iss, //CONNECTED APP ID
        "kid": kid, //CONNECTED APP SECRET ID
    };
    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = base64url(stringifiedHeader);
    var claimSet = {
        "sub": userName, // THE USER NAME, like aalteirac@tableau.com 
        "aud": "tableau",
        "nbf": Math.round(new Date().getTime() / 1000) - 100,
        "jti": new Date().getTime().toString(),
        "iss": iss,
        "scp": scp.split(","),
        "exp": Math.round(new Date().getTime() / 1000) + 100
    };
    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(claimSet));
    var encodedData = base64url(stringifiedData);
    var token = encodedHeader + "." + encodedData;
    var signature = CryptoJS.HmacSHA256(token, secret); //CONNECTED APP SECRET
    signature = base64url(signature);
    var signedToken = token + "." + signature;
    return signedToken;
}

function base64url(source) {
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
    return encodedSource;
}

class Analytics extends Component {
    constructor(props) {
        super(props);

        this.viz = React.createRef();
        this.scheduler = React.createRef();

        this.state = {
            supported: null
        };
    }

    checkCookie = event => {
        if (event.data === "3pcSupported" || event.data === "3pcUnsupported") {
            this.setState({ supported: event.data === "3pcSupported" });
            document.body.removeChild(document.getElementById('3pc'));
            window.removeEventListener("message", this.checkCookie);
        }
    }

    /////////////////////

    handleScheduleModal(value) {
        this.Scheduler.handleScheduleModal(value);
    }

    /////////////////////

    reloadData() {
        if (this.viz.current) {
            try {
                this.viz.current.refreshDataAsync();
            } catch (err) {
                toast.error('Refresh Error');
            }
        }
    }

    /////////////////////

    exportData(type) {
        if (this.viz.current) {
            try {
                this.viz.current.displayDialogAsync(type);
            } catch (err) {
                toast.error('Export Error');
            }
        }
    }

    /////////////////////

    init = event => {
        setInterval(() => {
            if (this.viz.current) {
                this.viz.current.pauseAutomaticUpdatesAsync().then(() => {
                    this.viz.current.resumeAutomaticUpdatesAsync().catch(() => {
                        window.location.reload();
                    });
                }).catch(() => {
                    window.location.reload();
                });
            }
        }, 300000);
        if (this.viz.current) {
            let sheet = this.viz.current.workbook.activeSheet;
            const most = sheet.worksheets.find(ws => ws.name == "Most Profitable All Shops");
            if (most) {
                most.applyFilterAsync("Id (Shops)", [], "all");
                most.applyFilterAsync("system", [], "all");
            }
            const least = sheet.worksheets.find(ws => ws.name == "Least Profitable All Shops");
            if (least) {
                least.applyFilterAsync("Id (Shops)", [], "all");
                least.applyFilterAsync("system", [], "all");
            }
        }
        this.viz.current.removeEventListener("firstinteractive", this.init);
    }
    /////////////////////

    componentDidMount() {
        const frame = document.createElement("iframe");
        frame.id = "3pc";
        frame.src = "https://chamithrepo.github.io/create-third-party-cookie/"; //Add your hosted domain url here
        frame.style.display = "none";
        frame.style.position = "fixed";
        document.body.appendChild(frame);
        window.addEventListener("message", this.checkCookie, false);
    }

    componentDidUpdate() {
        if (this.viz.current) {
            this.viz.current.addEventListener("firstinteractive", this.init);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextState.supported !== this.state.supported
            || nextProps.dashboard !== this.props.dashboard
            || nextProps.shop.id !== this.props.shop.id
            || nextProps.multishop !== this.props.multishop
        ) {
            return true;
        } else {
            return false;
        }
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            if (this.state.supported === null) {
                return (
                    <Paper elevation={5}>
                        <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                            <CircularProgress size={60} thickness={4} />
                            <Typography as='h1' color='primary'>Checking Browser Compatibility ...</Typography>
                        </Box>
                    </Paper>
                );
            } else {
                if (!this.state.supported) {
                    return (
                        <Paper elevation={5}>
                            <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                                <Typography as='h1' color='primary' gutterBottom>3rd Party Cookies Are Not Enabled</Typography>
                                <Divider flexItem sx={{ mb: 2 }} />
                                <Typography as='p'>
                                    The Analytics Dashboards require 3rd Party Cookies to be enabled in your browser. This is disabled by default in Safari and most incognito or private browser windows.<br /><br />
                                    For information on how to enable 3rd Party Cookies in your browser, please follow <a href='https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52' target="_blank">these instructions</a>.
                                </Typography>
                            </Box>
                        </Paper>
                    );
                } else {
                    var shop_ids = this.props.shop.id;
                    var systems = this.props.shop.system;
                    if (this.props.multishop !== null) {
                        shop_ids = '';
                        systems = '';
                        this.props.multishop.forEach((shop) => {
                            shop_ids += shop.id + ',';
                            systems += shop.system + ',';
                        });
                        shop_ids = shop_ids.substr(0, shop_ids.length - 1);
                        systems = systems.substr(0, systems.length - 1);
                    }
                    return (
                        <>
                            <Scheduler
                                email={this.props.auth0.user.email}
                                view={this.props.view}
                                shop={this.props.shop}
                                dashboard={this.props.dashboard}
                                onRef={ref => (this.Scheduler = ref)}
                            />
                            <Paper elevation={5} sx={{ height: '90vh', position: 'relative' }}>
                                <div
                                    style={{ background: 'url(./images/cplogo.png) no-repeat center', height: '100%' }}
                                >
                                    <tableau-viz
                                        id="tableauViz"
                                        ref={this.viz}
                                        src={"https://prod-useast-a.online.tableau.com/t/beachsidetireautorepair/views/" + this.props.dashboard}
                                        token={createToken(process.env.REACT_APP_VIZ_USERNAME, process.env.REACT_APP_VIZ_KID, process.env.REACT_APP_VIZ_SECRET, process.env.REACT_APP_VIZ_ISS, process.env.REACT_APP_VIZ_SCP)}
                                        toolbar="hidden"
                                        hide-tabs
                                    >
                                        <viz-filter field="Id (Shops)" value={shop_ids} ></viz-filter>
                                        <viz-filter field="system" value={systems} ></viz-filter>
                                    </tableau-viz>
                                </div>
                                <SpeedDial
                                    ariaLabel="SpeedDial"
                                    sx={{ position: 'absolute', left: '0', bottom: '-50px', zIndex: 1000 }}
                                    icon={<SpeedDialIcon />}
                                    direction='right'
                                >
                                    <SpeedDialAction
                                        key={'Reload'}
                                        icon={<SyncRoundedIcon />}
                                        tooltipTitle={'Reload'}
                                        onClick={() => { this.reloadData(); }}
                                    />
                                    <SpeedDialAction
                                        key={'Subscribe'}
                                        icon={<MoreTimeRoundedIcon />}
                                        tooltipTitle={'Subscribe'}
                                        onClick={() => { this.handleScheduleModal(true); }}
                                    />
                                    <SpeedDialAction
                                        key={'Export PDF'}
                                        icon={<PictureAsPdfRoundedIcon />}
                                        tooltipTitle={'Export PDF'}
                                        onClick={() => { this.exportData('export-pdf'); }}
                                    />
                                    <SpeedDialAction
                                        key={'Export CSV'}
                                        icon={<BackupTableRoundedIcon />}
                                        tooltipTitle={'Export CSV'}
                                        onClick={() => { this.exportData('export-cross-tab'); }}
                                    />
                                </SpeedDial>
                            </Paper>
                        </>
                    );
                }
            }
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(Analytics);
