import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

var api = axios.create({ withCredentials: true });

polyfill();

class SetupGoals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shown: null, categories: null, loading: false
        };
    }

    /////////////////////

    handleInputChange = event => {
        const { name, value } = event.target;
        var curr = this.state.shown;
        curr[name] = value;
        this.setState({
            shown: curr
        });
    }

    /////////////////////

    handleCategoryChange(goal, category) {
        if (goal > 0) {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/companies/mapping/goals/add',
                    { goal: goal, shop: this.props.shop.id, category: category, system: this.props.shop.system },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then(response => {
                    this.getCategories();
                    toast.success(`Goal Successfully Set`);
                }).catch((cerror) => {
                    this.setState({ loading: false });
                    toast.error(cerror.response.data);
                });
            }).catch((token_error) => {
                this.setState({ loading: false });
                console.log(token_error);
            });
        } else {
            toast.error('Goal Must Be A Positive Number');
        }
    }

    /////////////////////

    handleCategoryDelete(category) {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/companies/mapping/goals/delete',
                { shop: this.props.shop.id, category: category, system: this.props.shop.system },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(response => {
                this.getCategories();
                toast.success(`Category Goal Removed`);
            }).catch((cerror) => {
                this.setState({ loading: false });
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            this.setState({ loading: false });
            console.log(token_error);
        });
    }

    /////////////////////

    getCategories() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + `api/companies/mapping/goals/${this.props.shop.id}/${this.props.shop.system}`, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                this.setState({ shown: [], categories: response.data.rows });
            }).catch((cerror) => {
                this.setState({ loading: false });
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            this.setState({ loading: false });
            console.log(token_error);
        });
    }

    /////////////////////

    displayGoals() {
        var columns = [
            { field: 'col1', headerName: 'Category Name', flex: 1, minWidth: 250 },
            { field: 'col2', headerName: 'Category Type', flex: 1, minWidth: 250 },
            { field: 'col3', headerName: 'Custom/Standard', flex: 1, minWidth: 250 },
            {
                field: 'col4', headerName: 'Goal (Target % of all ROs)', flex: 1, minWidth: 250,
                renderCell: (params) => {
                    return (
                        <>
                            <TextField
                                id={params.row.category_id}
                                variant="standard"
                                placeholder='0'
                                type='number'
                                onChange={this.handleInputChange}
                                name={params.row.category_id}
                                value={this.state.shown.hasOwnProperty(params.row.category_id) ? this.state.shown[params.row.category_id] : params.row.col4}
                            />
                            {this.state.shown.hasOwnProperty(params.row.category_id) && this.state.shown[params.row.category_id] !== params.row.col4
                                ?
                                <IconButton
                                    aria-label="save"
                                    color='success'
                                    size='large'
                                    onClick={() => { this.handleCategoryChange(this.state.shown[params.row.category_id], params.row.category_id); }}
                                >
                                    <SaveRoundedIcon fontSize="inherit" />
                                </IconButton>
                                :
                                <IconButton aria-label="save" disabled color='success' size='large'>
                                    <SaveRoundedIcon fontSize="inherit" />
                                </IconButton>
                            }
                            &nbsp;&nbsp;
                            {params.row.col4 === '0'
                                ?
                                <IconButton aria-label="delete" disabled color='error' size='large'>
                                    <DeleteForeverRoundedIcon fontSize="inherit" />
                                </IconButton>
                                :
                                <IconButton
                                    aria-label="delete"
                                    color='error'
                                    size='large'
                                    onClick={() => { this.handleCategoryDelete(params.row.category_id); }}
                                >
                                    <DeleteForeverRoundedIcon fontSize="inherit" />
                                </IconButton>
                            }
                        </>
                    );
                },
                sortComparator: (v1, v2) => { return v1 - v2; }
            }
        ];

        var rows = [];
        this.state.categories.forEach((c, i) => {
            rows.push({
                id: i,
                col1: c.name,
                col2: c.category_type,
                col3: c.standard === '0' ? 'Custom' : 'Standard',
                col4: c.goal,
                category_id: c.id
            });
        });

        return (
            <>
                {
                    rows.length > 0
                        ?
                        <div style={{ height: '75vh', width: '100%' }}>
                            <DataGridPro
                                disableRowSelectionOnClick
                                rows={rows}
                                columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    }
                                }}
                            />
                        </div>
                        :
                        <>
                            <Typography variation='body2' gutterBottom>No Configured Categories Found</Typography>
                        </>
                }
            </>
        );
    }

    componentDidMount() {
        this.getCategories();
    }

    componentDidUpdate(prevProps) {
        var reload = false;
        if (prevProps.shop._id !== this.props.shop._id) {
            reload = true;
        }

        if (reload) {
            this.setState({ categories: null, shown: null }, () => {
                this.getCategories();
            });
        }
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            if (this.state.categories !== null) {
                return (
                    <Box flexGrow={1}>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={this.state.loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {this.displayGoals()}
                    </Box>
                );
            } else {
                return (
                    <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} thickness={4} />
                    </Box>
                );
            }
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(SetupGoals);
