import React, { Component } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

var api = axios.create({ withCredentials: true });

var cancelToken;

polyfill();

class ManageNotifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error_notify: null,
            notifyModal: false
        };
    }

    /////////////////////

    handleNotifyModal(val) {
        this.setState({ notifyModal: val });
    }

    /////////////////////

    handleMessageUpdate = (event) => {
        var tmp = this.state.error_notify;
        tmp.value = event.target.value;
        this.setState({ error_notify: tmp });
    }

    /////////////////////

    getNotify() {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("canceled");
        }

        cancelToken = axios.CancelToken.source();

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/settings/get/error_notify', { cancelToken: cancelToken.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
                this.setState({
                    error_notify: response.data
                });
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    updateNotify() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(process.env.REACT_APP_BASEURL + 'api/settings/update',
                this.state.error_notify,
                { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                    toast.success('Site Wide Notification Updated');
                }).catch((cerror) => {
                    toast.error(cerror.response.data);
                });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    componentDidMount() {
        this.getNotify();
        this.props.socket.on('settings', (msg) => {
            if (msg._id.toString() === this.state.error_notify._id) {
                this.getNotify();
            }
        });
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            if (this.state.error_notify !== null) {
                return (
                    <>
                        <Dialog open={this.state.notifyModal} onClose={() => { this.handleNotifyModal(false); }}>
                            <DialogTitle>{this.state.error_notify.status ? 'Disable' : 'Enable'} Notification?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <p>Are you sure you want to {this.state.error_notify.status ? 'Disable' : 'Enable'} the Site Wide Notification?</p>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color='error' onClick={() => { this.handleNotifyModal(false); }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color='success' onClick={() => {
                                    var tmp = this.state.error_notify;
                                    tmp.status = !tmp.status;
                                    this.setState({ error_notify: tmp }, () => {
                                        this.updateNotify();
                                        this.handleNotifyModal(false);
                                    });
                                }}>
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <FormGroup>
                            <TextField variant="standard" label='Notification Message Text' onChange={this.handleMessageUpdate} value={this.state.error_notify.value} sx={{ width: '100%' }} /><br />
                            <FormControlLabel control={
                                <Switch
                                    checked={this.state.error_notify.status}
                                    color='success'
                                    onClick={() => {
                                        this.handleNotifyModal(true);
                                    }}
                                />
                            } label={this.state.error_notify.status ? 'Site Wide Notification Enabled' : 'Site Wide Notification Disabled'} />
                        </FormGroup>
                    </>
                );
            } else {
                return (
                    <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} thickness={4} />
                    </Box>
                );
            }
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(ManageNotifications);
