import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import AutoDeleteRoundedIcon from '@mui/icons-material/AutoDeleteRounded';
import TableRow from '@mui/material/TableRow';
import cronstrue from 'cronstrue';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

var api = axios.create({ withCredentials: true });

polyfill();

class UserSchedules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            schedules: null
        };
    }

    /////////////////////

    getSchedules() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/schedule/getall',
                { email: this.props.auth0.user.email },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(response => {
                this.setState({
                    schedules: response.data
                });
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    deleteEmail(view, shop, type) {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/schedule/delete',
                { email: this.props.auth0.user.email, view: view, shop: shop, type: type },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(() => {
                this.getSchedules();
                toast.success('Subscription Delete Successful');
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    displaySchedules() {
        if (this.state.schedules.length > 0) {
            return this.state.schedules.map((schedule) => {
                var title = schedule.data.dashboard;
                var tparts = title.split(/\//);
                title = tparts[1];
                return (
                    <TableRow hover>
                        <TableCell>{schedule.data.shop.name}</TableCell>
                        <TableCell>{title}</TableCell>
                        <TableCell>{schedule.data.type}</TableCell>
                        <TableCell>{cronstrue.toString(schedule.data.schedule, { verbose: true })}</TableCell>
                        <TableCell>{schedule.repeatTimezone}</TableCell>
                        <TableCell>
                            <IconButton color='error'
                                onClick={() => {
                                    this.deleteEmail(schedule.data.view, schedule.data.shop, schedule.data.type);
                                }}>
                                <AutoDeleteRoundedIcon fontSize="large" />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            });
        } else {
            return (
                <TableRow>
                    <TableCell colSpan='6'>No Subscriptions Found. You can create subscriptions from the bottom left menu on a dashboard.</TableCell>
                </TableRow>
            );
        }
    }
    /////////////////////

    componentDidMount() {
        this.getSchedules();
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            if (this.state.schedules !== null) {
                return (
                    <Paper elevation={5} sx={{ p: 2 }}>
                        <Typography variant='h6' gutterBottom color='primary'>Manage Subscriptions</Typography>
                        <Divider flexItem sx={{ mb: 2 }} />
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Shop</TableCell>
                                        <TableCell>Dashboard</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Schedule</TableCell>
                                        <TableCell>TimeZone</TableCell>
                                        <TableCell>Cancel</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.displaySchedules()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                );
            } else {
                return (
                    <Paper elevation={5} sx={{ p: 2 }}>
                        <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                            <CircularProgress size={60} thickness={4} />
                        </Box>
                    </Paper>
                );
            }
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(UserSchedules);
