import React, { Component } from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

var api = axios.create({ withCredentials: true });

var cancelToken;

polyfill();

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
};

class ManageCompanies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            companies: null
        };
    }

    /////////////////////

    getCompanies() {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("canceled");
        }

        cancelToken = axios.CancelToken.source();

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/companies/all', { cancelToken: cancelToken.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
                this.setState({
                    companies: response.data
                });
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    displayCompaniesGrid() {
        var columns = [
            { field: 'col1', headerName: 'Company', flex: 1, minWidth: 200 },
            { field: 'col2', headerName: 'System', flex: .5, minWidth: 100 },
            { field: 'col3', headerName: 'Shop ID', flex: .5, minWidth: 100 },
            { field: 'col4', headerName: 'Address', flex: 1, minWidth: 200 },
            { field: 'col5', headerName: 'Phone', flex: .5, minWidth: 100 },
            {
                field: 'col6', headerName: 'RingCentral', flex: .25, minWidth: 100,
                valueGetter: (params) => {
                    var company = params.row.col6;

                    if (company.hasOwnProperty('rcJWT')) {
                        if (company.rcJWT.trim() !== '') {
                            return 'RingCentral';
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                },
                renderCell: (params) => {
                    var company = params.row.col6;

                    return (
                        <>
                            {
                                company.hasOwnProperty('rcJWT')
                                    ?
                                    company.rcJWT.trim() !== ''
                                        ? <CheckCircleRoundedIcon color='success' fontSize="large" />
                                        : <CancelRoundedIcon color='error' fontSize="large" />
                                    : <CancelRoundedIcon color='error' fontSize="large" />
                            }
                        </>
                    );
                }
            }
        ];

        var rows = [];
        this.state.companies.forEach((company, i) => {
            var name = company.name;
            if (this.state.companies.filter(x => x.name === company.name).length > 1 && company.hasOwnProperty('nickname')) {
                name = company.name + ' (' + company.nickname + ')';
            }
            rows.push({
                id: i,
                col1: name,
                col2: company.system,
                col3: company.id,
                col4: company.address.fullAddress,
                col5: formatPhoneNumber(company.phone),
                col6: company
            });
        });

        return (
            <>
                {
                    rows.length > 0
                        ?
                        <div style={{ height: '75vh', width: '100%', marginTop: '10px' }}>
                            <DataGridPro
                                rows={rows}
                                columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    }
                                }}
                            />
                        </div>
                        :
                        <>
                            <Typography variation='body2' gutterBottom>No Companies Found</Typography>
                        </>
                }
            </>
        );
    }

    /////////////////////

    componentDidMount() {
        this.getCompanies();
        this.props.socket.on('companies', (msg) => {
            this.getCompanies();
        });

        this.props.socket.on('connect', (msg) => {
            this.getCompanies();
        });
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            if (this.state.companies !== null) {
                return (
                    <>
                        {this.displayCompaniesGrid()}
                    </>
                );
            } else {
                return (
                    <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} thickness={4} />
                    </Box>
                );
            }
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(ManageCompanies);
