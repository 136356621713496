import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

var api = axios.create({ withCredentials: true });

polyfill();

class SetupMetrics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            years: [], year: moment().year(),
            types: [
                { key: 'General Repair and Maintenance', value: 'General Repair and Maintenance', text: 'General Repair and Maintenance' },
                { key: 'European Specialty', value: 'European Specialty', text: 'European Specialty' },
                { key: 'Performance Shops', value: 'Performance Shops', text: 'Performance Shops' }
            ],
            type: '',
            total_ros_per_day: null, average_ro_sales: null, avg_labor_ro: null, gross_profit_per_hour: null, gross_sales_per_hour: null,
            approved_hours: null, completed_hours: null, gross_sales: null, gross_profit: null, total_gross_dollars: null, upsell_target_percent: null,
            tekmetric_start_date: null
        };
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    handleDateChange = (value) => {
        this.setState({ tekmetric_start_date: Number(dayjs(value).format('YYYYMMDD')) });
    }

    handleYearChange = (value) => {
        if (value === '') { value = null; }
        this.setState({ year: value }, () => {
            this.getLatestMetrics();
        });
    }

    generateYears() {
        var years = [];
        for (var x = 2022; x <= 2050; x++) {
            years.push({ key: x, value: x, text: x });
        }
        this.setState({ years: years });
    }

    /////////////////////

    getLatestMetrics() {
        var latest = new Object();
        if (this.props.shop.hasOwnProperty('metrics')) {
            this.props.shop.metrics.forEach((metric) => {
                if (metric.year === this.state.year) {
                    if (latest.hasOwnProperty(metric.metric)) {
                        if (moment(metric.timestamp).isAfter(latest[metric.metric].timestamp)) {
                            latest[metric.metric].yearly_target = metric.yearly_target;
                            latest[metric.metric].timestamp = metric.timestamp;
                        }
                    } else {
                        latest[metric.metric] = new Object();
                        latest[metric.metric].yearly_target = metric.yearly_target;
                        latest[metric.metric].timestamp = metric.timestamp;
                    }
                }
            });

            for (var prop in latest) {
                var obj = new Object();
                obj[prop] = latest[prop].yearly_target;
                this.setState(obj);
            }
        }

        if (this.props.shop.hasOwnProperty('type')) {
            this.setState({ type: this.props.shop.type });
        } else {
            this.setState({ type: '' });
        }

        if (Object.keys(latest).length === 0) {
            this.setState({
                total_ros_per_day: null, average_ro_sales: null, avg_labor_ro: null, gross_profit_per_hour: null, gross_sales_per_hour: null,
                approved_hours: null, completed_hours: null, gross_sales: null, gross_profit: null, total_gross_dollars: null, upsell_target_percent: null,
                tekmetric_start_date: null
            });
        }
    }

    /////////////////////

    updateCompany() {
        var values = new Object();
        for (var prop in this.state) {
            values[prop] = this.state[prop];
        }
        delete values.years;
        delete values.types;
        delete values.type;
        values.company = this.props.shop._id;

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(process.env.REACT_APP_BASEURL + 'api/companies/update', { values: values }, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                toast.success('Successfully updated Company Metrics for ' + values.year);
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    handleTypeChange = (value) => {
        this.setState({ type: value }, () => {
            if (value !== '') {
                this.props.auth0.getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0AUDIENCE,
                    scope: process.env.REACT_APP_AUTH0SCOPE
                }).then((token) => {
                    api.get(process.env.REACT_APP_BASEURL + 'api/companies/update/type/' + this.props.shop._id + '/' + value, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                        toast.success('Successfully updated Shop Type');
                    }).catch((cerror) => {
                        toast.error(cerror.response.data);
                    });
                }).catch((token_error) => {
                    console.log(token_error);
                });
            }
        });
    }

    /////////////////////

    componentDidMount() {
        this.generateYears();
        this.getLatestMetrics();
    }

    componentDidUpdate(prevProps) {
        var reload = false;
        if (prevProps.shop._id === this.props.shop._id) {
            if (prevProps.shop.hasOwnProperty('metrics') && this.props.shop.hasOwnProperty('metrics')) {
                if (prevProps.shop.metrics.length < this.props.shop.metrics.length) {
                    reload = true;
                }
            } else if (this.props.shop.hasOwnProperty('metrics')) {
                reload = true;
            }

            if (prevProps.shop.hasOwnProperty('type') && this.props.shop.hasOwnProperty('type')) {
                if (prevProps.shop.type !== this.props.shop.type) {
                    reload = true;
                }
            } else if (this.props.shop.hasOwnProperty('type')) {
                reload = true;
            }
        } else {
            reload = true;
        }

        if (reload) {
            this.getLatestMetrics();
        }
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            return (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                    <Box>
                        <Typography variant='h6' gutterBottom color='primary'>Shop Options</Typography>
                        <Divider flexItem sx={{ mb: 2 }} />
                        <Stack spacing={2} direction='column'>
                            <Box>
                                <FormControl size="small">
                                    <InputLabel id="year-select-label">Year</InputLabel>
                                    <Select
                                        labelId='year-select-label'
                                        label='Year'
                                        id="year-select"
                                        value={this.state.year}
                                        onChange={(e, data) => { this.handleYearChange(data.props.value) }}
                                        size="small"
                                    >
                                        {this.state.years.map((year) => {
                                            return <MenuItem value={year.value}>{year.text}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                                <Button variant="contained" endIcon={<SaveRoundedIcon />} color='success' onClick={() => { this.updateCompany(); }} sx={{ ml: 2 }}>
                                    Save Targets
                                </Button>
                            </Box>
                            <Divider flexItem sx={{ mb: 2 }} />
                            <Box>
                                <FormControl sx={{ minWidth: '300px' }} size="small">
                                    <InputLabel id="type-select-label">Shop Type</InputLabel>
                                    <Select
                                        labelId='type-select-label'
                                        label='Shop Type'
                                        id="year-select"
                                        value={this.state.type}
                                        onChange={(e, data) => { this.handleTypeChange(data.props.value) }}
                                        size="small"
                                    >
                                        {this.state.types.map((type) => {
                                            return <MenuItem value={type.value}>{type.text}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                    </Box>
                    <Box>
                        <Typography variant='h6' gutterBottom color='primary'>{this.state.year} Annual Targets</Typography>
                        <Divider flexItem sx={{ mb: 2 }} />
                        <Stack spacing={{ xs: 2, lg: 4 }} direction={{ xs: 'column', lg: 'row' }}>
                            <Stack spacing={2} direction='column'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label={this.props.shop.system.charAt(0).toUpperCase() + this.props.shop.system.slice(1) + ' Start Date'}
                                        inputFormat="MM/DD/YYYY"
                                        value={this.state.tekmetric_start_date ? moment(this.state.tekmetric_start_date.toString(), 'YYYYMMDD').toDate() : new Date()}
                                        onChange={this.handleDateChange}
                                        renderInput={(params) => <TextField {...params} variant='standard' />}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    id="ro-basic"
                                    label="Total RO's"
                                    variant="standard"
                                    placeholder='5000'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='total_ros_per_day'
                                    value={this.state.total_ros_per_day ? this.state.total_ros_per_day : ""}
                                />
                                <TextField
                                    id="avgro-basic"
                                    label="Average RO's"
                                    variant="standard"
                                    placeholder='500'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='average_ro_sales'
                                    value={this.state.average_ro_sales ? this.state.average_ro_sales : ""}
                                />
                            </Stack>

                            <Stack spacing={2} direction='column'>
                                <TextField
                                    id="pph-basic"
                                    label="Gross Profit Per Hour"
                                    variant="standard"
                                    placeholder='150'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='gross_profit_per_hour'
                                    value={this.state.gross_profit_per_hour ? this.state.gross_profit_per_hour : ""}
                                />
                                <TextField
                                    id="sph-basic"
                                    label="Gross Sales Per Hour"
                                    variant="standard"
                                    placeholder='250'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='gross_sales_per_hour'
                                    value={this.state.gross_sales_per_hour ? this.state.gross_sales_per_hour : ""}
                                />
                                <TextField
                                    id="gs-basic"
                                    label="Gross Sales"
                                    variant="standard"
                                    placeholder='3000000'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='gross_sales'
                                    value={this.state.gross_sales ? this.state.gross_sales : ""}
                                />
                            </Stack>

                            <Stack spacing={2} direction='column'>
                                <TextField
                                    id="ah-basic"
                                    label="Approved Hours"
                                    variant="standard"
                                    placeholder='15000'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='approved_hours'
                                    value={this.state.approved_hours ? this.state.approved_hours : ""}
                                />
                                <TextField
                                    id="ch-basic"
                                    label="Completed Hours"
                                    variant="standard"
                                    placeholder='15000'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='completed_hours'
                                    value={this.state.completed_hours ? this.state.completed_hours : ""}
                                />
                                <TextField
                                    id="utp-basic"
                                    label="Upsell Target %"
                                    variant="standard"
                                    placeholder='45'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='upsell_target_percent'
                                    value={this.state.upsell_target_percent ? this.state.upsell_target_percent : ""}
                                />
                            </Stack>

                            <Stack spacing={2} direction='column'>
                                <TextField
                                    id="pm-basic"
                                    label="Profit Margin %"
                                    variant="standard"
                                    placeholder='60'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='gross_profit'
                                    value={this.state.gross_profit ? this.state.gross_profit : ""}
                                />
                                <TextField
                                    id="is-basic"
                                    label="Total Gross Dollars Presented"
                                    variant="standard"
                                    placeholder='4000000'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='total_gross_dollars'
                                    value={this.state.total_gross_dollars ? this.state.total_gross_dollars : ""}
                                />
                                <TextField
                                    id="elr-basic"
                                    label="Avg Labor Hours per RO"
                                    variant="standard"
                                    placeholder='2.5'
                                    type='number'
                                    onChange={this.handleInputChange}
                                    name='avg_labor_ro'
                                    value={this.state.avg_labor_ro ? this.state.avg_labor_ro : ""}
                                />
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            );
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(SetupMetrics);
