import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import AutoDeleteRoundedIcon from '@mui/icons-material/AutoDeleteRounded';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

var api = axios.create({ withCredentials: true });

polyfill();

class Scheduler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scheduleModal: false, value: "1",
            dailyHour: 7, dailyMin: 0, dailyAM: 'PM', dailyExists: false,
            weeklyHour: 7, weeklyMin: 0, weeklyAM: 'PM', weeklyDay: '1', weeklyExists: false,
            monthlyHour: 7, monthlyMin: 0, monthlyAM: 'PM', monthlyDay: '1', monthlyExists: false
        };
    }

    handleChange = (event, value) => {
        this.setState({ value: value });
    };

    handleDailyHourChange = (event) => {
        this.setState({ dailyHour: event.target.value });
    };

    handleDailyMinChange = (event) => {
        this.setState({ dailyMin: event.target.value });
    };

    handleDailyAMChange = (event) => {
        this.setState({ dailyAM: event.target.value });
    };

    handleWeeklyHourChange = (event) => {
        this.setState({ weeklyHour: event.target.value });
    };

    handleWeeklyMinChange = (event) => {
        this.setState({ weeklyMin: event.target.value });
    };

    handleWeeklyAMChange = (event) => {
        this.setState({ weeklyAM: event.target.value });
    };

    handleWeeklyDayChange = (event) => {
        this.setState({ weeklyDay: event.target.value });
    };

    handleMonthlyHourChange = (event) => {
        this.setState({ monthlyHour: event.target.value });
    };

    handleMonthlyMinChange = (event) => {
        this.setState({ monthlyMin: event.target.value });
    };

    handleMonthlyAMChange = (event) => {
        this.setState({ monthlyAM: event.target.value });
    };

    handleMonthlyDayChange = (event) => {
        this.setState({ monthlyDay: event.target.value });
    };

    /////////////////////

    handleScheduleModal(value) {
        this.setState({ scheduleModal: value });
    }

    /////////////////////

    getSchedules() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/schedule/get',
                { email: this.props.auth0.user.email, view: this.props.view, shop: this.props.shop, dashboard: this.props.dashboard },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var dailyHour = 7;
                var dailyMin = 0;
                var dailyAM = 'PM';
                var dailyExists = false;

                var weeklyHour = 7;
                var weeklyMin = 0;
                var weeklyAM = 'PM';
                var weeklyDay = 1;
                var weeklyExists = false;

                var monthlyHour = 7;
                var monthlyMin = 0;
                var monthlyAM = 'PM';
                var monthlyDay = 1;
                var monthlyExists = false;

                response.data.forEach((schedule) => {
                    if (schedule.data.type === 'Daily') {
                        var cron = schedule.data.schedule;
                        var parts = cron.split(/\s/);
                        dailyMin = parts[0];
                        dailyHour = parts[1];
                        dailyAM = 'AM';
                        if (dailyHour >= 12) {
                            if (dailyHour > 12) {
                                dailyHour = dailyHour - 12;
                            }
                            dailyAM = 'PM';
                        } else if (dailyHour < 1) {
                            dailyHour = 12;
                        }
                        dailyExists = true;
                    } else if (schedule.data.type === 'Weekly') {
                        var cron = schedule.data.schedule;
                        var parts = cron.split(/\s/);
                        weeklyMin = parts[0];
                        weeklyHour = parts[1];
                        weeklyDay = parts[4];
                        weeklyAM = 'AM';
                        if (weeklyHour >= 12) {
                            if (weeklyHour > 12) {
                                weeklyHour = weeklyHour - 12;
                            }
                            weeklyAM = 'PM';
                        } else if (weeklyHour < 1) {
                            weeklyHour = 12;
                        }
                        weeklyExists = true;
                    } else if (schedule.data.type === 'Monthly') {
                        var cron = schedule.data.schedule;
                        var parts = cron.split(/\s/);
                        monthlyMin = parts[0];
                        monthlyHour = parts[1];
                        monthlyDay = parts[2];
                        monthlyAM = 'AM';
                        if (monthlyHour >= 12) {
                            if (monthlyHour > 12) {
                                monthlyHour = monthlyHour - 12;
                            }
                            monthlyAM = 'PM';
                        } else if (monthlyHour < 1) {
                            monthlyHour = 12;
                        }
                        monthlyExists = true;
                    }
                });

                this.setState({
                    dailyHour: dailyHour, dailyMin: dailyMin, dailyAM: dailyAM, dailyExists: dailyExists,
                    weeklyHour: weeklyHour, weeklyMin: weeklyMin, weeklyAM: weeklyAM, weeklyDay: weeklyDay, weeklyExists: weeklyExists,
                    monthlyHour: monthlyHour, monthlyMin: monthlyMin, monthlyAM: monthlyAM, monthlyDay: monthlyDay, monthlyExists: monthlyExists
                });
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    scheduleEmail(type, schedule) {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/schedule/start',
                { email: this.props.auth0.user.email, view: this.props.view, shop: this.props.shop, dashboard: this.props.dashboard, type: type, schedule: schedule },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(() => {
                this.getSchedules();
                toast.success('Subscription Successful');
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    deleteEmail(type) {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/schedule/delete',
                { email: this.props.auth0.user.email, view: this.props.view, shop: this.props.shop, type: type },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(() => {
                this.getSchedules();
                toast.success('Subscription Delete Successful');
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    componentDidMount() {
        this.props.onRef(this)
        this.getSchedules();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.scheduleModal === false && this.state.scheduleModal === true) {
            this.setState({ value: "1" }, () => {
                this.getSchedules();
            });
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            var hours = [];
            for (var x = 1; x < 13; x++) {
                hours.push(<MenuItem value={x}>{x}</MenuItem>);
            }
            var mins = [];
            for (var x = 0; x < 60; x++) {
                mins.push(<MenuItem value={x}>{x}</MenuItem>);
            }
            var days = [];
            for (var x = 0; x < 29; x++) {
                days.push(<MenuItem value={x}>{x}</MenuItem>);
            }
            var title = this.props.dashboard;
            var tparts = title.split(/\//);
            title = tparts[1];
            return (
                <Dialog open={this.state.scheduleModal} onClose={() => { this.handleScheduleModal(false); }} fullWidth={true} maxWidth={'xs'}>
                    <DialogTitle sx={{ borderBottom: 1, borderColor: 'divider' }}>{title}<br /><small>({this.props.shop.name})</small></DialogTitle>
                    <DialogContent sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <DialogContentText>
                            <TabContext value={this.state.value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={this.handleChange}>
                                        <Tab label="Daily" value="1" />
                                        <Tab label="Weekly" value="2" />
                                        <Tab label="Monthly" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <p><small>Dashboard will be emailed Daily at :</small></p>
                                    <Stack spacing={2} direction={{ xs: 'row' }}>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="daily-hour-label">Hour</InputLabel>
                                                <Select
                                                    labelId="daily-hour-label"
                                                    id="daily-hour"
                                                    value={this.state.dailyHour}
                                                    label="Hour"
                                                    onChange={this.handleDailyHourChange}
                                                >
                                                    {hours}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="daily-min-label">Minute</InputLabel>
                                                <Select
                                                    labelId="daily-min-label"
                                                    id="daily-min"
                                                    value={this.state.dailyMin}
                                                    label="Minute"
                                                    onChange={this.handleDailyMinChange}
                                                >
                                                    {mins}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="daily-am-label">AM/PM</InputLabel>
                                                <Select
                                                    labelId="daily-am-label"
                                                    id="daily-am"
                                                    value={this.state.dailyAM}
                                                    label="AM/PM"
                                                    onChange={this.handleDailyAMChange}
                                                >
                                                    <MenuItem value='AM'>AM</MenuItem>
                                                    <MenuItem value='PM'>PM</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Stack>
                                    <br />
                                    <Button variant="contained" endIcon={<MoreTimeRoundedIcon />} color='success' onClick={() => {
                                        var hour = this.state.dailyHour;
                                        var min = this.state.dailyMin;
                                        if (this.state.dailyAM === 'PM') {
                                            if (hour < 12) {
                                                hour = hour + 12;
                                            }
                                        } else if (hour === 12) {
                                            hour = 0;
                                        }
                                        this.scheduleEmail('Daily', `${min} ${hour} * * *`);
                                    }}>
                                        Save
                                    </Button>
                                    {
                                        this.state.dailyExists
                                            ?
                                            <>
                                                &nbsp;&nbsp;
                                                <Button variant="contained" endIcon={<AutoDeleteRoundedIcon />} color='error' onClick={() => {
                                                    this.deleteEmail('Daily');
                                                }}>
                                                    Remove
                                                </Button>
                                            </>
                                            :
                                            <></>
                                    }
                                </TabPanel>
                                <TabPanel value="2">
                                    <p><small>Dashboard will be emailed Weekly at :</small></p>
                                    <FormControl fullWidth>
                                        <InputLabel id="weekly-day-label">Day of Week</InputLabel>
                                        <Select
                                            labelId="weekly-day-label"
                                            id="weekly-day"
                                            value={this.state.weeklyDay}
                                            label="Day of Week"
                                            onChange={this.handleWeeklyDayChange}
                                        >
                                            <MenuItem value='1'>Monday</MenuItem>
                                            <MenuItem value='2'>Tuesday</MenuItem>
                                            <MenuItem value='3'>Wednesday</MenuItem>
                                            <MenuItem value='4'>Thursday</MenuItem>
                                            <MenuItem value='5'>Friday</MenuItem>
                                            <MenuItem value='6'>Saturday</MenuItem>
                                            <MenuItem value='7'>Sunday</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br /><br />
                                    <Stack spacing={2} direction={{ xs: 'row' }}>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="weekly-hour-label">Hour</InputLabel>
                                                <Select
                                                    labelId="weekly-hour-label"
                                                    id="weekly-hour"
                                                    value={this.state.weeklyHour}
                                                    label="Hour"
                                                    onChange={this.handleWeeklyHourChange}
                                                >
                                                    {hours}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="weekly-min-label">Minute</InputLabel>
                                                <Select
                                                    labelId="weekly-min-label"
                                                    id="weekly-min"
                                                    value={this.state.weeklyMin}
                                                    label="Minute"
                                                    onChange={this.handleWeeklyMinChange}
                                                >
                                                    {mins}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="weekly-am-label">AM/PM</InputLabel>
                                                <Select
                                                    labelId="weekly-am-label"
                                                    id="weekly-am"
                                                    value={this.state.weeklyAM}
                                                    label="AM/PM"
                                                    onChange={this.handleWeeklyAMChange}
                                                >
                                                    <MenuItem value='AM'>AM</MenuItem>
                                                    <MenuItem value='PM'>PM</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Stack>
                                    <br />
                                    <Button variant="contained" endIcon={<MoreTimeRoundedIcon />} color='success' onClick={() => {
                                        var hour = this.state.weeklyHour;
                                        var min = this.state.weeklyMin;
                                        var day = this.state.weeklyDay;
                                        if (this.state.weeklyAM === 'PM') {
                                            if (hour < 12) {
                                                hour = hour + 12;
                                            }
                                        } else if (hour === 12) {
                                            hour = 0;
                                        }
                                        this.scheduleEmail('Weekly', `${min} ${hour} * * ${day}`);
                                    }}>
                                        Save
                                    </Button>
                                    {
                                        this.state.weeklyExists
                                            ?
                                            <>
                                                &nbsp;&nbsp;
                                                <Button variant="contained" endIcon={<AutoDeleteRoundedIcon />} color='error' onClick={() => {
                                                    this.deleteEmail('Weekly');
                                                }}>
                                                    Remove
                                                </Button>
                                            </>
                                            :
                                            <></>
                                    }
                                </TabPanel>
                                <TabPanel value="3">
                                    <p><small>Dashboard will be emailed Monthly at :</small></p>
                                    <Stack spacing={2} direction={{ xs: 'row' }}>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="monthly-hour-label">Day</InputLabel>
                                                <Select
                                                    labelId="monthly-hour-label"
                                                    id="monthly-hour"
                                                    value={this.state.monthlyDay}
                                                    label="Day"
                                                    onChange={this.handleMonthlyDayChange}
                                                >
                                                    {days}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="monthly-hour-label">Hour</InputLabel>
                                                <Select
                                                    labelId="monthly-hour-label"
                                                    id="monthly-hour"
                                                    value={this.state.monthlyHour}
                                                    label="Hour"
                                                    onChange={this.handleMonthlyHourChange}
                                                >
                                                    {hours}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="monthly-min-label">Minute</InputLabel>
                                                <Select
                                                    labelId="monthly-min-label"
                                                    id="monthly-min"
                                                    value={this.state.monthlyMin}
                                                    label="Minute"
                                                    onChange={this.handleMonthlyMinChange}
                                                >
                                                    {mins}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <FormControl fullWidth>
                                                <InputLabel id="monthly-am-label">AM/PM</InputLabel>
                                                <Select
                                                    labelId="monthly-am-label"
                                                    id="monthly-am"
                                                    value={this.state.monthlyAM}
                                                    label="AM/PM"
                                                    onChange={this.handleMonthlyAMChange}
                                                >
                                                    <MenuItem value='AM'>AM</MenuItem>
                                                    <MenuItem value='PM'>PM</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Stack>
                                    <br />
                                    <Button variant="contained" endIcon={<MoreTimeRoundedIcon />} color='success' onClick={() => {
                                        var hour = this.state.monthlyHour;
                                        var min = this.state.monthlyMin;
                                        var day = this.state.monthlyDay;
                                        if (this.state.monthlyAM === 'PM') {
                                            if (hour < 12) {
                                                hour = hour + 12;
                                            }
                                        } else if (hour === 12) {
                                            hour = 0;
                                        }
                                        this.scheduleEmail('Monthly', `${min} ${hour} ${day} * *`);
                                    }}>
                                        Save
                                    </Button>
                                    {
                                        this.state.monthlyExists
                                            ?
                                            <>
                                                &nbsp;&nbsp;
                                                <Button variant="contained" endIcon={<AutoDeleteRoundedIcon />} color='error' onClick={() => {
                                                    this.deleteEmail('Monthly');
                                                }}>
                                                    Remove
                                                </Button>
                                            </>
                                            :
                                            <></>
                                    }
                                </TabPanel>
                            </TabContext>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color='primary' onClick={() => { this.handleScheduleModal(false); }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(Scheduler);