import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

var api = axios.create({ withCredentials: true });

polyfill();

class SetupDates extends Component {
    constructor(props) {
        super(props);
        this.calendar = React.createRef();

        this.state = {
            Mon: false, Tues: false, Weds: false, Thurs: false, Fri: false, Sat: false, Sun: false,
            events: []
        };
    }

    handleDayChange = (data, label) => {
        var day = new Object();
        day[label] = data;
        this.setState(day, () => {
            var values = new Object();
            for (var prop in this.state) {
                values[prop] = this.state[prop];
            }
            values.company = this.props.shop._id;
            delete values.events;

            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(process.env.REACT_APP_BASEURL + 'api/companies/weekdays', { values: values }, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                    toast.success('Updated Operating Day : ' + label);
                }).catch((cerror) => {
                    toast.error(cerror.response.data);
                });
            }).catch((token_error) => {
                console.log(token_error);
            });
        });
    };

    /////////////////////

    handleDateClick = (arg) => {
        var found = false;
        this.state.events.forEach((event) => {
            if (event.start === moment(arg.dateStr).format('YYYY-MM-DD')) {
                found = true;
            }
        });

        if (!found) {
            var values = new Object();
            values.company = this.props.shop._id;
            values.date = moment(arg.dateStr).format('YYYY-MM-DD');

            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(process.env.REACT_APP_BASEURL + 'api/companies/holidays/add', { values: values }, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                    toast.success('Added a Holiday');
                }).catch((cerror) => {
                    toast.error(cerror.response.data);
                });
            }).catch((token_error) => {
                console.log(token_error);
            });
        } else {
            toast.error(moment(arg.dateStr).format('MM/DD/YYYY') + ' is already marked as a Holiday');
        }
    }

    /////////////////////

    handleEventClick = (arg) => {
        var values = new Object();
        values.company = this.props.shop._id;
        values.date = moment(arg.event.start).format('YYYY-MM-DD');

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(process.env.REACT_APP_BASEURL + 'api/companies/holidays/remove', { values: values }, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                toast.success('Removed a Holiday');
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    getWeekdays() {
        if (this.props.shop.hasOwnProperty('weekdays')) {
            this.setState({
                Mon: this.props.shop.weekdays.Mon,
                Tues: this.props.shop.weekdays.Tues,
                Weds: this.props.shop.weekdays.Weds,
                Thurs: this.props.shop.weekdays.Thurs,
                Fri: this.props.shop.weekdays.Fri,
                Sat: this.props.shop.weekdays.Sat,
                Sun: this.props.shop.weekdays.Sun
            });
        } else {
            this.setState({
                Mon: false, Tues: false, Weds: false, Thurs: false, Fri: false, Sat: false, Sun: false
            });
        }
    }

    /////////////////////

    getEvents() {
        if (this.props.shop.hasOwnProperty('holidays')) {
            var events = [];
            this.props.shop.holidays.forEach((event) => {
                events.push({
                    title: 'Holiday',
                    start: moment(event).add(1, 'days').format('YYYY-MM-DD'),
                    allDay: true
                });
            });
            this.setState({ events: events });
        } else {
            this.setState({ events: [] });
        }
    }

    /////////////////////

    componentDidMount() {
        this.getWeekdays();
        this.getEvents();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shop._id === this.props.shop._id) {
            if (prevProps.shop.hasOwnProperty('weekdays') && this.props.shop.hasOwnProperty('weekdays')) {
                if (
                    prevProps.shop.weekdays.Mon !== this.props.shop.weekdays.Mon
                    || prevProps.shop.weekdays.Tues !== this.props.shop.weekdays.Tues
                    || prevProps.shop.weekdays.Weds !== this.props.shop.weekdays.Weds
                    || prevProps.shop.weekdays.Thurs !== this.props.shop.weekdays.Thurs
                    || prevProps.shop.weekdays.Fri !== this.props.shop.weekdays.Fri
                    || prevProps.shop.weekdays.Sat !== this.props.shop.weekdays.Sat
                    || prevProps.shop.weekdays.Sun !== this.props.shop.weekdays.Sun
                ) {
                    this.getWeekdays();
                }
            } else if (this.props.shop.hasOwnProperty('weekdays')) {
                this.getWeekdays();
            } else if (prevProps.shop.hasOwnProperty('weekdays')) {
                this.setState({
                    Mon: false, Tues: false, Weds: false, Thurs: false, Fri: false, Sat: false, Sun: false
                });
            }

            if (prevProps.shop.hasOwnProperty('holidays') && this.props.shop.hasOwnProperty('holidays')) {
                if (prevProps.shop.holidays.length !== this.props.shop.holidays.length) {
                    this.getEvents();
                }
            } else if (this.props.shop.hasOwnProperty('holidays')) {
                this.getEvents();
            } else if (prevProps.shop.hasOwnProperty('holidays')) {
                this.setState({ events: [] });
            }
        } else {
            this.getWeekdays();
            this.getEvents();
        }
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            return (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                    <Box>
                        <Typography variant='h6' gutterBottom color='primary' width={{ xs: '100%', sm: '160px' }}>Operating Days</Typography>
                        <Divider flexItem sx={{ mb: 2 }} />
                        <Stack spacing={2} direction='column'>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.Sun}
                                            onChange={(e, data) => { this.handleDayChange(data, "Sun"); }}
                                        />
                                    }
                                    label="Sun"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.Mon}
                                            onChange={(e, data) => { this.handleDayChange(data, "Mon"); }}
                                        />
                                    }
                                    label="Mon"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.Tues}
                                            onChange={(e, data) => { this.handleDayChange(data, "Tues"); }}
                                        />
                                    }
                                    label="Tues"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.Weds}
                                            onChange={(e, data) => { this.handleDayChange(data, "Weds"); }}
                                        />
                                    }
                                    label="Weds"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.Thurs}
                                            onChange={(e, data) => { this.handleDayChange(data, "Thurs"); }}
                                        />
                                    }
                                    label="Thurs"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.Fri}
                                            onChange={(e, data) => { this.handleDayChange(data, "Fri"); }}
                                        />
                                    }
                                    label="Fri"
                                />
                            </Box>
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.Sat}
                                            onChange={(e, data) => { this.handleDayChange(data, "Sat"); }}
                                        />
                                    }
                                    label="Sat"
                                />
                            </Box>
                        </Stack>
                    </Box>
                    <Box>
                        <Typography variant='h6' gutterBottom color='primary'>Mark Holidays on the Calendar</Typography>
                        <Divider flexItem sx={{ mb: 2 }} />
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            dateClick={this.handleDateClick}
                            ref={this.calendar}
                            events={this.state.events}
                            eventClick={this.handleEventClick}
                            height='auto'
                            titleFormat={{ year: 'numeric', month: 'short' }}
                        />
                    </Box>
                </Stack>
            );
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(SetupDates);
