import React, { Component } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

var api = axios.create({ withCredentials: true });

var cancelToken;
var cancelTokenC;

polyfill();

class ManageGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reload: false,
            loading: false,
            groups: null,
            companies: null,
            curGroupId: null,
            curGroupName: null,
            addGroupName: null,
            addGroupCompanyT: [],
            addGroupCompanyV: [],
            addGroupName: null, addGroupModal: false, deleteGroupModal: false,
        };
    }

    /////////////////////

    getGroups() {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("canceled");
        }

        cancelToken = axios.CancelToken.source();

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/companies/groups/all', { cancelToken: cancelToken.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
                this.setState({
                    groups: response.data, reload: false
                });
            }).catch((cerror) => {
                this.setState({
                    reload: false
                });
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            this.setState({
                reload: false
            });
            console.log(token_error);
        });
    }

    /////////////////////

    getCompanies() {
        if (typeof cancelTokenC != typeof undefined) {
            cancelTokenC.cancel("canceled");
        }

        cancelTokenC = axios.CancelToken.source();

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/companies/all', { cancelToken: cancelTokenC.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
                var companies = [];
                response.data.forEach((company) => {
                    if (response.data.filter(x => x.name === company.name).length > 1 && company.hasOwnProperty('nickname')) {
                        companies.push({ key: company.id + '~' + company.system, value: company.id + '~' + company.system, text: company.name + ' (' + company.nickname + ')' });
                    } else {
                        companies.push({ key: company.id + '~' + company.system, value: company.id + '~' + company.system, text: company.name });
                    }
                });
                this.setState({
                    companies: companies
                });
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    handleGroupCompanyChange(group, value) {
        this.setState({ loading: true });
        var group_companies = [];
        var f = false;
        group.shops.forEach((company) => {
            group_companies.push(company.shop_id + '~' + company.system);
            if (company.shop_id + '~' + company.system === value) {
                f = true;
            }
        });
        if (!f) {
            group_companies.push(value);
        } else {
            group_companies = group_companies.filter((x) => {
                return x !== value;
            });
        }

        var shop_err = false;

        group_companies.forEach((shop) => {
            var parts = shop.split("~");
            var shop_id = parts[0];
            var system = parts[1];
            this.state.groups.forEach((loop_group) => {
                if (group.id !== loop_group.id) {
                    loop_group.shops.forEach((s) => {
                        if (Number(s.shop_id) === Number(shop_id) && s.system === system) {
                            shop_err = true;
                        }
                    });
                }
            });
        });

        if (!shop_err) {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/companies/groups/update',
                    { group: group.id, shops: group_companies },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    toast.success(group.group_name + " Updated");
                    this.setState({ loading: false, reload: true });
                }).catch((error) => {
                    toast.error(error.response.data);
                    this.setState({ loading: false, reload: true });
                });
            }).catch((token_error) => {
                console.log(token_error);
                this.setState({ loading: false, reload: true });
            });
        } else {
            this.setState({ loading: false, reload: true });
            toast.error('Companies can only be in a single group.');
        }
    }

    /////////////////////

    handleDeleteGroupModal(value) {
        this.setState({ deleteGroupModal: value });
    }

    deleteGroup() {
        this.setState({ loading: true });
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/companies/groups/delete', { group: this.state.curGroupId }, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var name = this.state.curGroupName;
                this.setState({ curGroupId: null, curGroupName: null, loading: false, reload: true });
                toast.success(name + ' Deleted');
            }).catch((error) => {
                this.setState({ curGroupId: null, curGroupName: null, loading: false, reload: true });
                toast.error(error.response.data);
            });
        }).catch((token_error) => {
            this.setState({ curGroupId: null, curGroupName: null, loading: false, reload: true });
            console.log(token_error);
        });
    }

    /////////////////////

    handleAddGroupModal(value) {
        if (value) {
            this.setState({ addGroupModal: value, addGroupName: null, addGroupCompanyV: [], addGroupCompanyT: [] });
        } else {
            this.setState({ addGroupModal: value });
        }
    }

    handleAddGroupName = (event) => {
        this.setState({ addGroupName: event.target.value });
    }

    handleAddCompanyChange(value) {
        var values = this.state.addGroupCompanyV;
        var text = this.state.addGroupCompanyT;

        var f = false;
        values.forEach((v) => {
            if (v === value) {
                f = true;
            }
        });
        if (f) {
            this.state.companies.forEach((company) => {
                if (company.value === value) {
                    values = values.filter((x) => {
                        return x !== value;
                    });
                    text = text.filter((x) => {
                        return x !== company.text;
                    });
                }
            });
        } else {
            values.push(value);
            this.state.companies.forEach((company) => {
                if (company.value === value) {
                    text.push(company.text);
                }
            });
        }

        this.setState({ addGroupCompanyV: values, addGroupCompanyT: text });
    }

    addGroup() {
        this.setState({ loading: true });
        var exists = false;
        var group_err = false;
        var shop_err = false;

        this.state.groups.forEach((group) => {
            if (group.group_name === this.state.addGroupName) {
                exists = true;
                group_err = true;
            }
        });
        this.state.addGroupCompanyV.forEach((shop) => {
            var parts = shop.split("~");
            var shop_id = parts[0];
            var system = parts[1];
            this.state.groups.forEach((group) => {
                group.shops.forEach((s) => {
                    if (Number(s.shop_id) === Number(shop_id) && s.system === system) {
                        exists = true;
                        shop_err = true;
                    }
                });
            });
        });
        if (
            this.state.addGroupName !== null && !exists
        ) {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/companies/groups/add',
                    { name: this.state.addGroupName, shops: this.state.addGroupCompanyV },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    var name = this.state.addGroupName;
                    this.setState({
                        addGroupName: null, addGroupCompanyV: [], addGroupCompanyT: [], loading: false, reload: true
                    });
                    toast.success(name + " Added");
                }).catch((error) => {
                    this.setState({
                        addGroupName: null, addGroupCompanyV: [], addGroupCompanyT: [], loading: false, reload: true
                    });
                    toast.error(error.response.data);
                });
            }).catch((token_error) => {
                this.setState({
                    addGroupName: null, addGroupCompanyV: [], addGroupCompanyT: [], loading: false, reload: true
                });
                console.log(token_error);
            });
        } else {
            this.setState({
                addGroupName: null, addGroupCompanyV: [], addGroupCompanyT: [], loading: false, reload: true
            });
            if (group_err) {
                toast.error('A Group Name is required and must be unique.');
            }
            if (shop_err) {
                toast.error('Companies can only be in a single group.');
            }
        }
    }

    /////////////////////

    displayGroupsGrid() {
        var columns = [
            { field: 'col1', headerName: 'Group', flex: 1, minWidth: 200 },
            {
                field: 'col2', headerName: 'Companies', flex: 1, minWidth: 120,
                valueGetter: (params) => {
                    var group = params.row.group;

                    var group_companies = [];
                    this.state.companies.forEach((company) => {
                        group.shops.forEach((gc) => {
                            if ((gc.shop_id + '~' + gc.system) === company.key) {
                                group_companies.push(company.text);
                            }
                        });
                    });
                    return group_companies.toString();
                },
                renderCell: (params) => {
                    var group = params.row.group;

                    var group_companies = [];
                    this.state.companies.forEach((company) => {
                        group.shops.forEach((gc) => {
                            if ((gc.shop_id + '~' + gc.system) === company.key) {
                                group_companies.push(company.text);
                            }
                        });
                    });

                    return (
                        <FormControl sx={{ minWidth: 100 }} size="small">
                            <InputLabel id="group-company-select-label">Companies</InputLabel>
                            <Select
                                labelId="group-company-select-label"
                                id="group-company-select"
                                multiple
                                autoWidth
                                value={group_companies}
                                onChange={(e, data) => { this.handleGroupCompanyChange(group, data.props.value) }}
                                input={<OutlinedInput label='Companies' />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {this.state.companies.map((company) => (
                                    <MenuItem key={company.key} value={company.value}>
                                        <Checkbox checked={group_companies.indexOf(company.text) > -1} />
                                        <ListItemText primary={company.text} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                }
            },
            {
                field: 'col3', headerName: 'Delete', flex: .25, minWidth: 100,
                renderCell: (params) => {
                    var group = params.row.group;

                    return (
                        <IconButton
                            aria-label="delete"
                            color='error'
                            size='large'
                            onClick={() => {
                                this.setState({ curGroupId: group.id, curGroupName: group.group_name }, () => {
                                    this.handleDeleteGroupModal(true);
                                });
                            }}
                        >
                            <DeleteForeverRoundedIcon fontSize="inherit" />
                        </IconButton>
                    );
                }
            }
        ];

        var rows = [];
        this.state.groups.forEach((group, i) => {
            rows.push({
                id: i,
                col1: group.group_name,
                group: group,
            });
        });

        return (
            <>
                {
                    rows.length > 0
                        ?
                        <div style={{ height: '75vh', width: '100%', marginTop: '10px' }}>
                            <DataGridPro
                                density="comfortable"
                                rows={rows}
                                columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    }
                                }}
                            />
                        </div>
                        :
                        <>
                            <Typography variation='body2' gutterBottom>No Groups Found</Typography>
                        </>
                }
            </>
        );
    }

    /////////////////////

    componentDidMount() {
        this.getGroups();

        this.getCompanies();
        this.props.socket.on('companies', (msg) => {
            this.getCompanies();
        });

        this.props.socket.on('connect', (msg) => {
            this.getGroups();
            this.getCompanies();
        });
    }

    /////////////////////

    componentDidUpdate() {
        if (this.state.reload) {
            this.getGroups();
        }
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            if (this.state.groups !== null && this.state.companies !== null) {
                return (
                    <>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: 10000000 }}
                            open={this.state.loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        <Dialog open={this.state.addGroupModal} onClose={() => { this.handleAddGroupModal(false); }}>
                            <DialogTitle>Add Group</DialogTitle>
                            <DialogContent>
                                <TextField variant="standard" label='Group Name' onChange={this.handleAddGroupName} sx={{ width: 300 }} /><br /><br />
                                <FormControl sx={{ width: 300 }} size="small">
                                    <InputLabel id="nuser-company-select-label">Companies</InputLabel>
                                    <Select
                                        labelId="nuser-company-select-label"
                                        id="nuser-company-select"
                                        multiple
                                        autoWidth
                                        value={this.state.addGroupCompanyT}
                                        onChange={(e, data) => { this.handleAddCompanyChange(data.props.value) }}
                                        input={<OutlinedInput label='Companies' />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {this.state.companies.map((company) => (
                                            <MenuItem key={company.key} value={company.value}>
                                                <Checkbox checked={this.state.addGroupCompanyT.indexOf(company.text) > -1} />
                                                <ListItemText primary={company.text} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color='error' onClick={() => { this.handleAddGroupModal(false); }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" endIcon={<GroupsRoundedIcon />} color='success' onClick={() => { this.addGroup(); this.handleAddGroupModal(false); }}>
                                    Add Group
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.deleteGroupModal} onClose={() => { this.handleDeleteGroupModal(false); }}>
                            <DialogTitle>Delete Group</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <p>Are you sure you want to delete {this.state.curGroupName}?</p>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color='error' onClick={() => { this.handleDeleteGroupModal(false); }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" endIcon={<DeleteForeverRoundedIcon />} color='success' onClick={() => { this.deleteGroup(); this.handleDeleteGroupModal(false); }}>
                                    Delete Group
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Button variant="contained" endIcon={<GroupsRoundedIcon />} color='success' onClick={() => { this.handleAddGroupModal(true); }}>
                            Add a New Group
                        </Button>
                        {this.displayGroupsGrid()}
                    </>
                );
            } else {
                return (
                    <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} thickness={4} />
                    </Box>
                );
            }
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(ManageGroups);
