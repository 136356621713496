import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

var api = axios.create({ withCredentials: true });

polyfill();

class SetupJobs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: null, shown: null, selected: [], multiplecategory: "", category: "0", categories: null, loading: false
        };
    }

    /////////////////////

    handleGridCategoryChange() {
        if (this.state.category === "0") {
            this.setState({ shown: [], selected: [] }, () => { this.setState({ shown: this.state.jobs, loading: false }) });
        } else if (this.state.category === "-1") {
            var tmp = [];
            this.state.jobs.forEach((job) => {
                if (!job.current) {
                    tmp.push(job);
                }
            });
            this.setState({ shown: [], selected: [] }, () => { this.setState({ shown: tmp, loading: false }); });
        } else {
            var tmp = [];
            this.state.jobs.forEach((job) => {
                if (Number(job.current) === Number(this.state.category)) {
                    tmp.push(job);
                }
            });
            this.setState({ shown: [], selected: [] }, () => { this.setState({ shown: tmp, loading: false }); });
        }
    }

    /////////////////////

    handleCategoryChange(id, job) {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/companies/mapping/add',
                { shop: this.props.shop.id, category: id, job: job, system: this.props.shop.system },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(response => {
                var tmp = this.state.jobs;
                tmp.forEach((val, i) => {
                    if (val.job === job) {
                        tmp[i].current = id;
                    }
                });

                var tmps = this.state.shown;
                tmps.forEach((val, i) => {
                    if (val.job === job) {
                        tmps[i].current = id;
                    }
                });

                this.setState({ jobs: tmp, shown: tmps }, () => { this.handleGridCategoryChange(); });
                toast.success(`Job Successfully Mapped`);
            }).catch((cerror) => {
                this.setState({ loading: false });
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            this.setState({ loading: false });
            console.log(token_error);
        });
    }

    /////////////////////

    handleCategoryDelete(job) {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/companies/mapping/delete',
                { shop: this.props.shop.id, job: job, system: this.props.shop.system },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(response => {
                var tmp = this.state.jobs;
                tmp.forEach((val, i) => {
                    if (val.job === job) {
                        tmp[i].current = null;
                    }
                });

                var tmps = this.state.shown;
                tmps.forEach((val, i) => {
                    if (val.job === job) {
                        tmps[i].current = null;
                    }
                });

                this.setState({ jobs: tmp, shown: tmps }, () => { this.handleGridCategoryChange(); });
                toast.success(`Job Mapping Removed`);
            }).catch((cerror) => {
                this.setState({ loading: false });
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            this.setState({ loading: false });
            console.log(token_error);
        });
    }

    /////////////////////

    handleMultipleChange() {
        if (this.state.selected.length === 0) {
            this.setState({ multiplecategory: "", loading: false });
            toast.error("No Jobs Selected");
        } else {
            var jobs = this.state.selected;
            var cat = this.state.multiplecategory;
            if (cat === "-1") {
                this.props.auth0.getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0AUDIENCE,
                    scope: process.env.REACT_APP_AUTH0SCOPE
                }).then((token) => {
                    api.post(
                        process.env.REACT_APP_BASEURL + 'api/companies/mapping/deletemultiple',
                        { shop: this.props.shop.id, category: cat, jobs: jobs, system: this.props.shop.system },
                        { headers: { Authorization: `Bearer ${token}` } }
                    ).then(response => {
                        var tmp = this.state.jobs;
                        tmp.forEach((val, i) => {
                            if (jobs.filter(e => e.col1 === val.job).length > 0) {
                                tmp[i].current = null;
                            }
                        });

                        var tmps = this.state.shown;
                        tmps.forEach((val, i) => {
                            if (jobs.filter(e => e.col1 === val.job).length > 0) {
                                tmp[i].current = null;
                            }
                        });

                        this.setState({ jobs: tmp, shown: tmps, multiplecategory: "" }, () => { this.handleGridCategoryChange(); });
                        toast.success(`Job Mappings Removed`);
                    }).catch((cerror) => {
                        this.setState({ loading: false });
                        toast.error(cerror.response.data);
                    });
                }).catch((token_error) => {
                    this.setState({ loading: false });
                    console.log(token_error);
                });
            } else {
                this.props.auth0.getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0AUDIENCE,
                    scope: process.env.REACT_APP_AUTH0SCOPE
                }).then((token) => {
                    api.post(
                        process.env.REACT_APP_BASEURL + 'api/companies/mapping/addmultiple',
                        { shop: this.props.shop.id, category: cat, jobs: this.state.selected, system: this.props.shop.system },
                        { headers: { Authorization: `Bearer ${token}` } }
                    ).then(response => {
                        var tmp = this.state.jobs;
                        tmp.forEach((val, i) => {
                            if (jobs.filter(e => e.col1 === val.job).length > 0) {
                                tmp[i].current = cat;
                            }
                        });

                        var tmps = this.state.shown;
                        tmps.forEach((val, i) => {
                            if (jobs.filter(e => e.col1 === val.job).length > 0) {
                                tmp[i].current = cat;
                            }
                        });

                        this.setState({ jobs: tmp, shown: tmps, multiplecategory: "" }, () => { this.handleGridCategoryChange(); });
                        toast.success(`Jobs Successfully Mapped`);
                    }).catch((cerror) => {
                        this.setState({ loading: false });
                        toast.error(cerror.response.data);
                    });
                }).catch((token_error) => {
                    this.setState({ loading: false });
                    console.log(token_error);
                });
            }
        }
    }

    /////////////////////

    getCategories() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/companies/mapping/categories', { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                this.setState({ categories: response.data.rows });
            }).catch((cerror) => {
                this.setState({ loading: false });
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            this.setState({ loading: false });
            console.log(token_error);
        });
    }

    /////////////////////

    getJobs() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/companies/mapping/jobs/' + this.props.shop.id + '/' + this.props.shop.system, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
                this.setState({ jobs: response.data.rows, shown: response.data.rows });
            }).catch((cerror) => {
                this.setState({ loading: false });
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            this.setState({ loading: false });
            console.log(token_error);
        });
    }

    /////////////////////

    displayJobs() {
        var columns = [
            { field: 'col1', headerName: 'Job Name', flex: 1, minWidth: 250 },
            {
                field: 'col2', headerName: 'Category', flex: .75, minWidth: 200,
                renderCell: (params) => {
                    return (
                        <>
                            <Select
                                id="demo-simple-select"
                                value={params.formattedValue ? params.formattedValue : 0}
                                onChange={(e, data) => { this.handleCategoryChange(data.props.value, params.row.col1) }}
                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, flex: 'auto' }}
                            >
                                <MenuItem value="0" disabled>Select A Category</MenuItem>
                                {
                                    this.state.categories.map((category) => {
                                        return <MenuItem value={category.id}>{category.name}&nbsp;<small><i>{category.standard === "0" ? 'custom' : 'standard'}</i></small></MenuItem>;
                                    })
                                }
                            </Select>
                            {!params.formattedValue
                                ?
                                <IconButton aria-label="delete" disabled color='error' size='large'>
                                    <DeleteForeverRoundedIcon fontSize="inherit" />
                                </IconButton>
                                :
                                <IconButton
                                    aria-label="delete"
                                    color='error'
                                    size='large'
                                    onClick={() => { this.handleCategoryDelete(params.row.col1); }}
                                >
                                    <DeleteForeverRoundedIcon fontSize="inherit" />
                                </IconButton>
                            }
                        </>
                    );
                },
                sortComparator: (v1, v2) => { return v1 - v2; }
            },
            { field: 'col3', headerName: '12 Month Total', flex: .5, minWidth: 100, type: 'number' },
            {
                field: 'col4', headerName: 'Average Cost', flex: .5, minWidth: 100, type: 'number',
                renderCell: (params) => {
                    return (
                        <>${params.formattedValue}</>
                    );
                },
                sortComparator: (v1, v2) => { return v1 - v2; }
            }
        ];

        var rows = [];
        this.state.shown.forEach((job, i) => {
            rows.push({
                id: i,
                col1: job.job,
                col2: job.current,
                col3: job.total,
                col4: job.average
            });
        });

        var pageOption = [];
        if (rows.length >= 100) {
            pageOption.push(100);
        }
        if (rows.length >= 500) {
            pageOption.push(500);
        }
        if (rows.length >= 1000) {
            pageOption.push(1000);
        }
        pageOption.push(rows.length);

        return (
            <>
                <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-select-label">Jobs by Category</InputLabel>
                            <Select
                                labelId="demo-select-label"
                                id="demo-select"
                                label="Jobs By Category"
                                defaultValue={"0"}
                                onChange={(e, data) => { this.setState({ category: data.props.value }, () => { this.handleGridCategoryChange(); }); }}
                                sx={{ flex: 'auto' }}
                            >
                                <MenuItem value="0">All Jobs</MenuItem>
                                <MenuItem value="-1">Un-Categorized Jobs</MenuItem>
                                {
                                    this.state.categories.map((category) => {
                                        return <MenuItem value={category.id}>{category.name}&nbsp;<small><i>{category.standard === "0" ? 'custom' : 'standard'}</i></small></MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-label">Categorize Selected Jobs</InputLabel>
                            <Select
                                labelId="demo-simple-label"
                                id="demo-simple-select"
                                label="Categorize Selected Jobs"
                                value={this.state.multiplecategory}
                                onChange={(e, data) => { this.setState({ multiplecategory: data.props.value, loading: true }, () => { this.handleMultipleChange(); }); }}
                                sx={{ flex: 'auto' }}
                            >
                                <MenuItem value="-1">Un-Categorize</MenuItem>
                                {
                                    this.state.categories.map((category) => {
                                        return <MenuItem value={category.id}>{category.name}&nbsp;<small><i>{category.standard === "0" ? 'custom' : 'standard'}</i></small></MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    rows.length > 0
                        ?
                        <div style={{ height: '75vh', width: '100%' }}>
                            <DataGridPro
                                checkboxSelection
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={(ids) => {
                                    const idSet = new Set(ids);
                                    const selected = rows.filter((row) => idSet.has(row.id));
                                    this.setState({ selected: selected });
                                }}
                                apiRef={this.props.apiRef}
                                rows={rows}
                                columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    }
                                }}
                                pagination
                                pageSizeOptions={pageOption}
                            />
                        </div>
                        :
                        <>
                            <Typography variation='body2' gutterBottom>No Job History Found</Typography>
                        </>
                }
            </>
        );
    }

    componentDidMount() {
        this.getCategories();
        this.getJobs();
    }

    componentDidUpdate(prevProps) {
        var reload = false;
        if (prevProps.shop._id !== this.props.shop._id) {
            reload = true;
        }

        if (reload) {
            this.setState({ jobs: null, categories: null }, () => {
                this.getJobs();
                this.getCategories();
            });
        }
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            if (this.state.jobs !== null && this.state.shown !== null && this.state.categories !== null) {
                return (
                    <Box flexGrow={1}>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={this.state.loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {this.displayJobs()}
                    </Box>
                );
            } else {
                return (
                    <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} thickness={4} />
                    </Box>
                );
            }
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(SetupJobs);
