import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { GridRow, GridCell, GridColumnHeaders, DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Backdrop from '@mui/material/Backdrop';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

const MemoizedRow = React.memo(GridRow);
const MemoizedCell = React.memo(GridCell);
const MemoizedGridToolbar = React.memo(GridToolbar);
const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
};

var api = axios.create({ withCredentials: true });

var cancelToken;
var cancelTokenC;
var cancelTokenD;

polyfill();

class ManageUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            addUserModal: false, deleteUserModal: false, editUserModal: false,
            curId: null, curUserId: null, curUserEmail: null,
            addUserEmail: null, addUserFname: null, addUserLname: null, addUserCompanyV: [], addUserCompanyT: [], addUserRolesV: [], addUserRolesT: [],
            email: null, user: null, users: null, companies: [], displayCompanies: [], dashboards: [],
            roles: [
                { key: 'owner', value: 'owner', text: 'Owner' },
                { key: 'coach', value: 'coach', text: 'Coach' },
                { key: 'sa', value: 'sa', text: 'Service Advisor' },
            ]
        };
    }

    /////////////////////

    getCompanies() {
        if (typeof cancelTokenC != typeof undefined) {
            cancelTokenC.cancel("canceled");
        }

        cancelTokenC = axios.CancelToken.source();

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/companies/all', { cancelToken: cancelTokenC.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
                var companies = [];
                response.data.forEach((company) => {
                    if (response.data.filter(x => x.name === company.name).length > 1 && company.hasOwnProperty('nickname')) {
                        companies.push({ key: company._id, value: company._id, text: company.name + ' (' + company.nickname + ')' });
                    } else {
                        companies.push({ key: company._id, value: company._id, text: company.name });
                    }
                });
                this.setState({
                    companies: companies
                });
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    getDashboards() {
        if (typeof cancelTokenD != typeof undefined) {
            cancelTokenD.cancel("canceled");
        }

        cancelTokenD = axios.CancelToken.source();

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/dashboards/all', { cancelToken: cancelTokenD.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
                var dashboards = [];
                response.data.forEach((dashboard) => {
                    dashboards.push({ key: dashboard._id, value: dashboard._id, text: dashboard.title });
                });
                this.setState({ dashboards: dashboards });
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    getUsers() {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("canceled");
        }

        cancelToken = axios.CancelToken.source();

        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(process.env.REACT_APP_BASEURL + 'api/users/all', { cancelToken: cancelToken.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
                this.setState({
                    users: response.data
                });
            }).catch((cerror) => {
                toast.error(cerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    handleAddUserModal(value) {
        if (value) {
            this.setState({ addUserModal: value, addUserEmail: null, addUserFname: null, addUserLname: null, addUserCompanyV: [], addUserCompanyT: [], addUserRolesV: [], addUserRolesT: [] });
        } else {
            this.setState({ addUserModal: value });
        }
    }

    handleAddUserEmail = (event) => {
        this.setState({ addUserEmail: event.target.value });
    }

    handleAddUserFname = (event) => {
        this.setState({ addUserFname: event.target.value });
    }

    handleAddUserLname = (event) => {
        this.setState({ addUserLname: event.target.value });
    }

    handleCompanyChange(value) {
        var values = this.state.addUserCompanyV;
        var text = this.state.addUserCompanyT;

        var f = false;
        values.forEach((v) => {
            if (v === value) {
                f = true;
            }
        });
        if (f) {
            this.state.companies.forEach((company) => {
                if (company.value === value) {
                    values = values.filter((x) => {
                        return x !== value;
                    });
                    text = text.filter((x) => {
                        return x !== company.text;
                    });
                }
            });
        } else {
            values.push(value);
            this.state.companies.forEach((company) => {
                if (company.value === value) {
                    text.push(company.text);
                }
            });
        }

        this.setState({ addUserCompanyV: values, addUserCompanyT: text });
    }

    handleRoleChange(value) {
        var values = this.state.addUserRolesV;
        var text = this.state.addUserRolesT;

        var f = false;
        values.forEach((v) => {
            if (v === value) {
                f = true;
            }
        });
        if (f) {
            this.state.roles.forEach((role) => {
                if (role.value === value) {
                    values = values.filter((x) => {
                        return x !== value;
                    });
                    text = text.filter((x) => {
                        return x !== role.text;
                    });
                }
            });
        } else {
            values.push(value);
            this.state.roles.forEach((role) => {
                if (role.value === value) {
                    text.push(role.text);
                }
            });
        }

        this.setState({ addUserRolesV: values, addUserRolesT: text });
    }

    addUser() {
        this.setState({ loading: true });
        if (
            this.state.addUserEmail !== null &&
            this.state.addUserFname !== null &&
            this.state.addUserLname !== null &&
            this.state.addUserCompanyV.length > 0 &&
            this.state.addUserRolesV.length > 0
        ) {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/users/insert/' +
                    this.state.addUserEmail + '/' +
                    this.state.addUserFname + '/' +
                    this.state.addUserLname,
                    { companies: this.state.addUserCompanyV, roles: this.state.addUserRolesV },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    var email = this.state.addUserEmail;
                    this.setState({
                        addUserEmail: null, addUserFname: null, addUserLname: null, addUserCompanyV: [], addUserCompanyT: [], addUserRolesV: [], addUserRolesT: [], loading: false
                    });
                    toast.success('Added User : ' + email);
                }).catch((error) => {
                    this.setState({
                        addUserEmail: null, addUserFname: null, addUserLname: null, addUserCompanyV: [], addUserCompanyT: [], addUserRolesV: [], addUserRolesT: [], loading: false
                    });
                    toast.error(error.response.data);
                });
            }).catch((token_error) => {
                this.setState({
                    addUserEmail: null, addUserFname: null, addUserLname: null, addUserCompanyV: [], addUserCompanyT: [], addUserRolesV: [], addUserRolesT: [], loading: false
                });
                console.log(token_error);
            });
        } else {
            this.setState({
                addUserEmail: null, addUserFname: null, addUserLname: null, addUserCompanyV: [], addUserCompanyT: [], addUserRolesV: [], addUserRolesT: [], loading: false
            });
            toast.error('All Fields are Required.');
        }
    }

    /////////////////////

    handleUserCompanyChange(user, value) {
        this.setState({ loading: true });
        var user_companies = [];
        var f = false;
        user.companies.forEach((company) => {
            user_companies.push(company._id);
            if (company._id === value) {
                f = true;
            }
        });
        if (!f) {
            user_companies.push(value);
        } else {
            user_companies = user_companies.filter((x) => {
                return x !== value;
            });
        }

        if (user_companies.length === 0) {
            toast.error('You must assign at least one company to a user.');
            this.setState({ loading: false });
        } else {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/users/update/company/' + user._id,
                    { companies: user_companies },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    toast.success('Updated Companies : ' + user.email);
                    this.setState({ loading: false });
                }).catch((error) => {
                    toast.error(error.response.data);
                    this.setState({ loading: false });
                });
            }).catch((token_error) => {
                console.log(token_error);
                this.setState({ loading: false });
            });
        }
    }

    /////////////////////

    handleUserDashboardChange(user, value) {
        this.setState({ loading: true });
        var user_dashboards = [];
        var f = false;
        user.dashboards.forEach((dashboard) => {
            user_dashboards.push(dashboard._id);
            if (dashboard._id === value) {
                f = true;
            }
        });
        if (!f) {
            user_dashboards.push(value);
        } else {
            user_dashboards = user_dashboards.filter((x) => {
                return x !== value;
            });
        }

        if (user_dashboards.length === 0) {
            toast.error('You must assign at least one dashboard to a user.');
            this.setState({ loading: false });
        } else {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/users/update/dashboard/' + user._id,
                    { dashboards: user_dashboards },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    toast.success('Updated Dashboards : ' + user.email);
                    this.setState({ loading: false });
                }).catch((error) => {
                    toast.error(error.response.data);
                    this.setState({ loading: false });
                });
            }).catch((token_error) => {
                console.log(token_error);
                this.setState({ loading: false });
            });
        }
    }

    /////////////////////

    handleUserRoleChange(user, value) {
        this.setState({ loading: true });
        var user_roles = [];
        var f = false;
        user.roles.forEach((role) => {
            user_roles.push(role);
            if (role === value) {
                f = true;
            }
        });
        if (!f) {
            user_roles.push(value);
        } else {
            user_roles = user_roles.filter((x) => {
                return x !== value;
            });
        }

        if (user_roles.length === 0) {
            toast.error('You must assign at least one role to a user.');
            this.setState({ loading: false });
        } else {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + 'api/users/update/roles/' + user._id,
                    { roles: user_roles },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    toast.success('Updated Roles : ' + user.email);
                    this.setState({ loading: false });
                }).catch((error) => {
                    toast.error(error.response.data);
                    this.setState({ loading: false });
                });
            }).catch((token_error) => {
                console.log(token_error);
                this.setState({ loading: false });
            });
        }
    }

    /////////////////////

    handleDeleteUserModal(value) {
        this.setState({ deleteUserModal: value });
    }

    deleteUser() {
        this.setState({ loading: true });
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                process.env.REACT_APP_BASEURL + 'api/users/delete/' + this.state.curId + '/' + this.state.curUserId, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var email = this.state.curUserEmail;
                this.setState({
                    curUserEmail: null, curId: null, curUserId: null, loading: false
                });
                toast.success('Deleted User : ' + email);
            }).catch((error) => {
                this.setState({
                    curUserEmail: null, curId: null, curUserId: null, loading: false
                });
                toast.error(error.response.data);
            });
        }).catch((token_error) => {
            this.setState({
                curUserEmail: null, curId: null, curUserId: null, loading: false
            });
            console.log(token_error);
        });
    }

    /////////////////////

    toggleAdmin(value) {
        this.setState({ loading: true });
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                process.env.REACT_APP_BASEURL + 'api/users/admin/' + value + '/' + this.state.curId, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var email = this.state.curUserEmail;
                this.setState({
                    curUserEmail: null, curId: null, curUserId: null, loading: false
                });
                if (value) {
                    toast.success(email + ' is now a site Admin.');
                } else {
                    toast.success(email + ' is no longer a site Admin.');
                }
            }).catch((error) => {
                this.setState({
                    curUserEmail: null, curId: null, curUserId: null, loading: false
                });
                toast.error(error.response.data);
            });
        }).catch((token_error) => {
            this.setState({
                curUserEmail: null, curId: null, curUserId: null, loading: false
            });
            console.log(token_error);
        });
    }

    /////////////////////

    toggleAdvisor(value) {
        this.setState({ loading: true });
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                process.env.REACT_APP_BASEURL + 'api/users/advisor/' + value + '/' + this.state.curId, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var email = this.state.curUserEmail;
                this.setState({
                    curUserEmail: null, curId: null, curUserId: null, loading: false
                });
                if (value) {
                    toast.success(email + ' can use Service Advisor Assistant.');
                } else {
                    toast.success(email + ' can NOT use Service Advisor Assistant.');
                }
            }).catch((error) => {
                this.setState({
                    curUserEmail: null, curId: null, curUserId: null, loading: false
                });
                toast.error(error.response.data);
            });
        }).catch((token_error) => {
            this.setState({
                curUserEmail: null, curId: null, curUserId: null, loading: false
            });
            console.log(token_error);
        });
    }

    /////////////////////

    displayUserGrid() {
        var columns = [
            { field: 'col1', headerName: 'User', flex: 1, minWidth: 200 },
            {
                field: 'col2', headerName: 'Company', flex: 1, minWidth: 120,
                valueGetter: (params) => {
                    var user = params.row.user;

                    var user_companies = [];
                    this.state.companies.forEach((company) => {
                        user.companies.forEach((uc) => {
                            if (uc._id === company.key) {
                                user_companies.push(company.text);
                            }
                        });
                    });
                    return user_companies.toString();
                },
                renderCell: (params) => {
                    var user = params.row.user;

                    var user_companies = [];
                    this.state.companies.forEach((company) => {
                        user.companies.forEach((uc) => {
                            if (uc._id === company.key) {
                                user_companies.push(company.text);
                            }
                        });
                    });

                    return (
                        <FormControl sx={{ minWidth: 100 }} size="small">
                            <InputLabel id="user-company-select-label">Companies</InputLabel>
                            <Select
                                labelId="user-company-select-label"
                                id="user-company-select"
                                multiple
                                autoWidth
                                value={user_companies}
                                onChange={(e, data) => { this.handleUserCompanyChange(user, data.props.value) }}
                                input={<OutlinedInput label='Companies' />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {this.state.companies.map((company) => (
                                    <MenuItem key={company.key} value={company.value}>
                                        <Checkbox checked={user_companies.indexOf(company.text) > -1} />
                                        <ListItemText primary={company.text} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                }
            },
            {
                field: 'col3', headerName: 'Roles', flex: .5, minWidth: 120,
                valueGetter: (params) => {
                    var user = params.row.user;

                    var user_roles = [];
                    this.state.roles.forEach((role) => {
                        user.roles.forEach((ur) => {
                            if (ur === role.key) {
                                user_roles.push(role.text);
                            }
                        });
                    });
                    return user_roles.toString();
                },
                renderCell: (params) => {
                    var user = params.row.user;

                    var user_roles = [];
                    this.state.roles.forEach((role) => {
                        user.roles.forEach((ur) => {
                            if (ur === role.key) {
                                user_roles.push(role.text);
                            }
                        });
                    });

                    return (
                        <FormControl sx={{ minWidth: 100 }} size="small">
                            <InputLabel id="user-company-select-label">Roles</InputLabel>
                            <Select
                                labelId="user-role-select-label"
                                id="user-role-select"
                                multiple
                                autoWidth
                                value={user_roles}
                                onChange={(e, data) => { this.handleUserRoleChange(user, data.props.value) }}
                                input={<OutlinedInput label='Roles' />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {this.state.roles.map((role) => (
                                    <MenuItem key={role.key} value={role.value}>
                                        <Checkbox checked={user_roles.indexOf(role.text) > -1} />
                                        <ListItemText primary={role.text} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                }
            },
            {
                field: 'col4', headerName: 'Dashboards', flex: .75, minWidth: 120,
                valueGetter: (params) => {
                    var user = params.row.user;

                    var user_dashboards = [];
                    this.state.dashboards.forEach((dashboard) => {
                        user.dashboards.forEach((ud) => {
                            if (ud._id === dashboard.key) {
                                user_dashboards.push(dashboard.text);
                            }
                        });
                    });
                    return user_dashboards.toString();
                },
                renderCell: (params) => {
                    var user = params.row.user;

                    var user_dashboards = [];
                    this.state.dashboards.forEach((dashboard) => {
                        user.dashboards.forEach((ud) => {
                            if (ud._id === dashboard.key) {
                                user_dashboards.push(dashboard.text);
                            }
                        });
                    });

                    return (
                        <FormControl sx={{ minWidth: 100 }} size="small">
                            <InputLabel id="user-dash-select-label">Dashboards</InputLabel>
                            <Select
                                labelId="user-dash-select-label"
                                id="user-dash-select"
                                multiple
                                autoWidth
                                value={user_dashboards}
                                onChange={(e, data) => { this.handleUserDashboardChange(user, data.props.value) }}
                                input={<OutlinedInput label='Dasboards' />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {this.state.dashboards.map((dashboard) => (
                                    <MenuItem key={dashboard.key} value={dashboard.value}>
                                        <Checkbox checked={user_dashboards.indexOf(dashboard.text) > -1} />
                                        <ListItemText primary={dashboard.text} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                }
            },
            {
                field: 'col5', headerName: 'Advisor', flex: .25, minWidth: 100,
                valueGetter: (params) => {
                    var user = params.row.user;

                    if (user.advisor) {
                        return "Advisor";
                    } else {
                        return null;
                    }
                },
                renderCell: (params) => {
                    var user = params.row.user;

                    return (
                        <Switch
                            checked={user.advisor}
                            color='success'
                            onClick={() => {
                                this.setState({ curUserEmail: user.email, curId: user._id }, () => {
                                    this.toggleAdvisor(!user.advisor);
                                });
                            }}
                        />
                    );
                }
            },
            {
                field: 'col6', headerName: 'Admin', flex: .25, minWidth: 100,
                valueGetter: (params) => {
                    var user = params.row.user;

                    if (user.admin) {
                        return "Admin";
                    } else {
                        return null;
                    }
                },
                renderCell: (params) => {
                    var user = params.row.user;

                    return (
                        <>
                            {
                                user.admin && this.props.loggedInUser._id === user._id
                                    ?
                                    <Switch
                                        checked={true}
                                        disabled
                                        color='success'
                                    />
                                    :
                                    <Switch
                                        checked={user.admin}
                                        color='success'
                                        onClick={() => {
                                            this.setState({ curUserEmail: user.email, curId: user._id }, () => {
                                                this.toggleAdmin(!user.admin);
                                            });
                                        }}
                                    />
                            }
                        </>
                    );
                }
            },
            {
                field: 'col7', headerName: 'Delete', flex: .25, minWidth: 100,
                renderCell: (params) => {
                    var user = params.row.user;

                    return (
                        <>
                            {
                                user.email === this.props.loggedInUser.email
                                    ?
                                    <IconButton aria-label="delete" disabled color='error' size='large'>
                                        <DeleteForeverRoundedIcon fontSize="inherit" />
                                    </IconButton>
                                    :
                                    <IconButton
                                        aria-label="delete"
                                        color='error'
                                        size='large'
                                        onClick={() => {
                                            this.setState({ curUserEmail: user.email, curId: user._id, curUserId: user.auth0 }, () => {
                                                this.handleDeleteUserModal(true);
                                            });
                                        }}
                                    >
                                        <DeleteForeverRoundedIcon fontSize="inherit" />
                                    </IconButton>
                            }
                        </>
                    );
                }
            }
        ];

        var rows = [];
        this.state.users.forEach((user, i) => {
            rows.push({
                id: i,
                col1: user.fname + ' ' + user.lname + ' (' + user.email + ')',
                user: user
            });
        });

        var pageOption = [];
        if (rows.length >= 15) {
            pageOption.push(15);
        }
        if (pageOption.length === 0) {
            pageOption.push(rows.length);
        }

        return (
            <>
                {
                    rows.length > 0
                        ?
                        <div style={{ height: '75vh', width: '100%', marginTop: '10px' }}>
                            <DataGridPro
                                slots={{
                                    row: MemoizedRow,
                                    cell: MemoizedCell,
                                    toolbar: MemoizedGridToolbar,
                                    columnHeaders: MemoizedColumnHeaders,

                                }}
                                density="comfortable"
                                rows={rows}
                                columns={columns}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    }
                                }}
                                pagination
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: pageOption[0], page: 0 },
                                    },
                                }}
                                pageSizeOptions={pageOption}
                            />
                        </div>
                        :
                        <>
                            <Typography variation='body2' gutterBottom>No Users Found</Typography>
                        </>
                }
            </>
        );
    }

    /////////////////////

    componentDidMount() {
        this.getUsers();
        this.props.socket.on('users', (msg) => {
            this.getUsers();
        });

        this.getCompanies();
        this.props.socket.on('companies', (msg) => {
            this.getCompanies();
        });

        this.getDashboards();
        this.props.socket.on('dashboards', (msg) => {
            this.getDashboards();
        });

        this.props.socket.on('connect', (msg) => {
            this.getUsers();
            this.getCompanies();
            this.getDashboards();
        });
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            if (this.state.users !== null) {
                return (
                    <>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: 10000000 }}
                            open={this.state.loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Dialog open={this.state.addUserModal} onClose={() => { this.handleAddUserModal(false); }}>
                            <DialogTitle>Add User</DialogTitle>
                            <DialogContent>
                                <TextField variant="standard" label='Email' onChange={this.handleAddUserEmail} sx={{ width: 300 }} /><br /><br />
                                <TextField variant="standard" label='First Name' onChange={this.handleAddUserFname} sx={{ width: 300 }} /><br /><br />
                                <TextField variant="standard" label='Last Name' onChange={this.handleAddUserLname} sx={{ width: 300 }} /><br /><br />
                                <FormControl sx={{ width: 300 }} size="small">
                                    <InputLabel id="nuser-company-select-label">Companies</InputLabel>
                                    <Select
                                        labelId="nuser-company-select-label"
                                        id="nuser-company-select"
                                        multiple
                                        autoWidth
                                        value={this.state.addUserCompanyT}
                                        onChange={(e, data) => { this.handleCompanyChange(data.props.value) }}
                                        input={<OutlinedInput label='Companies' />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {this.state.companies.map((company) => (
                                            <MenuItem key={company.key} value={company.value}>
                                                <Checkbox checked={this.state.addUserCompanyT.indexOf(company.text) > -1} />
                                                <ListItemText primary={company.text} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl><br /><br />
                                <FormControl sx={{ width: 300 }} size="small">
                                    <InputLabel id="nuser-roles-select-label">Roles</InputLabel>
                                    <Select
                                        labelId="nuser-roles-select-label"
                                        id="nuser-roles-select"
                                        multiple
                                        autoWidth
                                        value={this.state.addUserRolesT}
                                        onChange={(e, data) => { this.handleRoleChange(data.props.value) }}
                                        input={<OutlinedInput label='Roles' />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {this.state.roles.map((role) => (
                                            <MenuItem key={role.key} value={role.value}>
                                                <Checkbox checked={this.state.addUserRolesT.indexOf(role.text) > -1} />
                                                <ListItemText primary={role.text} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color='error' onClick={() => { this.handleAddUserModal(false); }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" endIcon={<PersonAddAltRoundedIcon />} color='success' onClick={() => { this.addUser(); this.handleAddUserModal(false); }}>
                                    Add User
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.deleteUserModal} onClose={() => { this.handleDeleteUserModal(false); }}>
                            <DialogTitle>Delete User</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <p>Are you sure you want to delete {this.state.curUserEmail}?</p>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color='error' onClick={() => { this.handleDeleteUserModal(false); }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" endIcon={<DeleteForeverRoundedIcon />} color='success' onClick={() => { this.deleteUser(); this.handleDeleteUserModal(false); }}>
                                    Delete User
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Button variant="contained" endIcon={<PersonAddAltRoundedIcon />} color='success' onClick={() => { this.handleAddUserModal(true); }}>
                            Add a New User
                        </Button>
                        {this.displayUserGrid()}
                    </>
                );
            } else {
                return (
                    <Box sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} thickness={4} />
                    </Box>
                );
            }
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(ManageUsers);
