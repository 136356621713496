import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

import ManageUsers from './manage.users.com';
import ManageCompanies from './manage.companies.com';
import ManageGroups from './manage.groups.com';
import ManageNotifications from './manage.notifications.com';

var api = axios.create({ withCredentials: true });

polyfill();

class Manage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "1"
        };
    }

    handleChange = (event, value) => {
        this.setState({ value: value });
    };

    /////////////////////

    componentDidMount() {

    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            return (
                <Paper elevation={5}>
                    <TabContext value={this.state.value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={this.handleChange}>
                                <Tab label="Manage Users" value="1" />
                                <Tab label="Enrolled Companies" value="2" />
                                <Tab label="Manage Groups" value="3" />
                                <Tab label="Site Wide Notification" value="4" />
                            </TabList>
                        </Box>
                        <TabPanel value="1"><ManageUsers loggedInUser={this.props.loggedInUser} socket={this.props.socket} /></TabPanel>
                        <TabPanel value="2"><ManageCompanies loggedInUser={this.props.loggedInUser} socket={this.props.socket} /></TabPanel>
                        <TabPanel value="3"><ManageGroups loggedInUser={this.props.loggedInUser} socket={this.props.socket} /></TabPanel>
                        <TabPanel value="4"><ManageNotifications loggedInUser={this.props.loggedInUser} socket={this.props.socket} /></TabPanel>
                    </TabContext>
                </Paper>
            );
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(Manage);
