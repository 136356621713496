import React, { Component } from 'react';
import { blue } from '@mui/material/colors';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import CarCrashRoundedIcon from '@mui/icons-material/CarCrashRounded';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import SettingsIcon from '@mui/icons-material/Settings';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';

var api = axios.create({ withCredentials: true });

var cancelTokenName;
var cancelTokenPhone;

polyfill();

class CustomerSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            autoNameLoading: false, autoNameOptions: [], autoPhoneLoading: false, autoPhoneOptions: [],
            customerLoading: false, customer: null, vehicles: [], vehicleLoading: false, vehicle: null,
            jobs: [], onlyDeclined: false, customerRank: null, rankModal: false,
            shopRankConfig: {
                dts: new Date().getTime(),
                0: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 0,
                    aro_max: 100
                },
                1: {
                    ro_min: 0,
                    ro_max: 5,
                    aro_min: 0,
                    aro_max: 10000
                },
                2: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 100,
                    aro_max: 10000
                },
                3: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 250,
                    aro_max: 10000
                },
                4: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 350,
                    aro_max: 10000
                }
            }
        };
    }

    toggleOnlyDeclined() {
        this.setState({ onlyDeclined: !this.state.onlyDeclined });
    }

    handleVehicleChange(id) {
        this.setState({ vehicleLoading: true });

        var vehicle = null;
        this.state.vehicles.forEach((v) => {
            if (v.vehicle_id === id) {
                vehicle = v;
            }
        });

        if (vehicle) {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.get(
                    process.env.REACT_APP_BASEURL + 'api/charts/vehicle/jobs/' + this.props.shop.id + '/' + this.props.shop.system + '/' + this.state.customer.customer_id + '/' + vehicle.vehicle_id,
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then(dresponse => {
                    this.setState({
                        vehicle: vehicle,
                        jobs: dresponse.data.jobs,
                        vehicleLoading: false
                    });
                }).catch((derror) => {
                    toast.error(derror.response.data);
                    this.setState({ vehicle: vehicle, jobs: null, vehicleLoading: false });
                });
            }).catch((token_error) => {
                console.log(token_error);
                this.setState({ vehicle: vehicle, jobs: null, vehicleLoading: false });
            });
        } else {
            this.setState({ vehicle: vehicle, jobs: null, vehicleLoading: false });
        }
    }

    /////////////////////

    getNameOptions(val) {
        if (val.length > 0) {
            this.setState({ autoNameLoading: true }, () => {
                this.setState({ autoNameOptions: [] }, () => {
                    if ((val.match(/[a-z0-9]/gmi) || []).length > 2) {
                        if (typeof cancelTokenName != typeof undefined) {
                            cancelTokenName.cancel("canceled");
                        }

                        cancelTokenName = axios.CancelToken.source();

                        this.props.auth0.getAccessTokenSilently({
                            audience: process.env.REACT_APP_AUTH0AUDIENCE,
                            scope: process.env.REACT_APP_AUTH0SCOPE
                        }).then((token) => {
                            api.post(
                                process.env.REACT_APP_BASEURL + 'api/charts/customer/autocomplete/name/' + this.props.shop.id + '/' + this.props.shop.system,
                                { value: val },
                                { cancelTokenName: cancelTokenName.token, headers: { Authorization: `Bearer ${token}` } }
                            ).then(response => {
                                this.setState({
                                    autoNameOptions: response.data.rows,
                                    autoNameLoading: false
                                });
                            }).catch((cerror) => {
                                toast.error(cerror.response.data);
                                this.setState({ autoNameLoading: false });
                            });
                        }).catch((token_error) => {
                            console.log(token_error);
                            this.setState({ autoNameLoading: false });
                        });
                    }
                });
            });
        } else {
            this.setState({ autoNameLoading: false });
        }
    }

    debouncedNameSearch = debounce(async (val) => { this.getNameOptions(val) }, 300);

    /////////////////////

    getPhoneOptions(val) {
        if (val.length > 0) {
            this.setState({ autoPhoneLoading: true }, () => {
                this.setState({ autoPhoneOptions: [] }, () => {
                    if ((val.match(/[a-z0-9]/gmi) || []).length > 2) {
                        if (typeof cancelTokenPhone != typeof undefined) {
                            cancelTokenPhone.cancel("canceled");
                        }

                        cancelTokenPhone = axios.CancelToken.source();

                        this.props.auth0.getAccessTokenSilently({
                            audience: process.env.REACT_APP_AUTH0AUDIENCE,
                            scope: process.env.REACT_APP_AUTH0SCOPE
                        }).then((token) => {
                            api.post(
                                process.env.REACT_APP_BASEURL + 'api/charts/customer/autocomplete/phone/' + this.props.shop.id + '/' + this.props.shop.system,
                                { value: val },
                                { cancelTokenPhone: cancelTokenPhone.token, headers: { Authorization: `Bearer ${token}` } }
                            ).then(response => {
                                this.setState({
                                    autoPhoneOptions: response.data.rows,
                                    autoPhoneLoading: false
                                });
                            }).catch((cerror) => {
                                toast.error(cerror.response.data);
                                this.setState({ autoPhoneLoading: false });
                            });
                        }).catch((token_error) => {
                            console.log(token_error);
                            this.setState({ autoPhoneLoading: false });
                        });
                    }
                });
            });
        } else {
            this.setState({ autoPhoneLoading: false });
        }
    }

    debouncedPhoneSearch = debounce(async (val) => { this.getPhoneOptions(val) }, 300);

    /////////////////////

    getCustomer(val) {
        this.setState({ customerLoading: true }, () => {
            this.props.auth0.getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.get(
                    process.env.REACT_APP_BASEURL + 'api/charts/customer/' + this.props.shop.id + '/' + this.props.shop.system + '/' + val,
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then(response => {
                    this.setState({
                        vehicles: [],
                        vehicle: null,
                        jobs: [],
                        customer: response.data.customer,
                        customerLoading: false
                    }, () => {
                        api.get(
                            process.env.REACT_APP_BASEURL + 'api/charts/customer/get/' + this.state.customer.customer_id + '/' + this.state.customer.shop_id + '/' + this.state.customer.system,
                            { headers: { Authorization: `Bearer ${token}` } }
                        ).then(rresponse => {
                            var rank = null;
                            if (rresponse.data) {
                                rank = rresponse.data;
                            }
                            this.setState({
                                customerRank: rank
                            }, () => {
                                api.get(
                                    process.env.REACT_APP_BASEURL + 'api/charts/vehicle/' + this.props.shop.id + '/' + this.props.shop.system + '/' + val,
                                    { headers: { Authorization: `Bearer ${token}` } }
                                ).then(vresponse => {
                                    this.setState({
                                        vehicles: vresponse.data.vehicles
                                    });
                                }).catch((verror) => {
                                    toast.error(verror.response.data);
                                    this.setState({ customerLoading: false });
                                });
                            });
                        }).catch((rerror) => {
                            toast.error(rerror.response.data);
                            this.setState({ customerLoading: false });
                        });
                    });
                }).catch((cerror) => {
                    toast.error(cerror.response.data);
                    this.setState({ customerLoading: false });
                });
            }).catch((token_error) => {
                console.log(token_error);
                this.setState({ customerLoading: false });
            });
        });
    }

    /////////////////////

    getCustomerRank() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                process.env.REACT_APP_BASEURL + 'api/charts/customer/get/' + this.state.customer.customer_id + '/' + this.state.customer.shop_id + '/' + this.state.customer.system,
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(rresponse => {
                var rank = null;
                if (rresponse.data) {
                    rank = rresponse.data;
                }
                this.setState({
                    customerRank: rank
                });
            }).catch((rerror) => {
                toast.error(rerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    markCustomer(rank) {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                process.env.REACT_APP_BASEURL + 'api/charts/customer/update/' +
                this.state.customer.customer_id + '/' +
                this.state.customer.shop_id + '/' +
                this.state.customer.system + '/' +
                rank,
                { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                this.getCustomerRank();
                if (rank === 0) {
                    toast.success("Marked as Don't Book");
                }
                if (rank === 4) {
                    toast.success("Marked as a Best Customer");
                }
            }).catch((error) => {
                toast.error(error.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    removeCustomerRank() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                process.env.REACT_APP_BASEURL + 'api/charts/customer/delete/' + this.state.customerRank._id,
                { headers: { Authorization: `Bearer ${token}` } }
            ).then(rresponse => {
                this.setState({
                    customerRank: null
                }, () => {
                    toast.success("Manual Customer Ranking Removed");
                });
            }).catch((rerror) => {
                toast.error(rerror.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    setShopConfig() {
        if (this.props.shop.hasOwnProperty('rankConfig')) {
            this.setState({ shopRankConfig: this.props.shop.rankConfig });
        } else {
            var shopRankConfigDefault = {
                dts: new Date().getTime(),
                0: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 0,
                    aro_max: 100
                },
                1: {
                    ro_min: 0,
                    ro_max: 5,
                    aro_min: 0,
                    aro_max: 10000
                },
                2: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 100,
                    aro_max: 10000
                },
                3: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 250,
                    aro_max: 10000
                },
                4: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 350,
                    aro_max: 10000
                }
            };
            this.setState({ shopRankConfig: shopRankConfigDefault });
        }
    }

    /////////////////////

    handleRankModal(value) {
        if (value) {
            if (this.props.shop.hasOwnProperty('rankConfig')) {
                this.setState({ shopRankConfig: this.props.shop.rankConfig, rankModal: value });
            } else {
                var shopRankConfigDefault = {
                    dts: new Date().getTime(),
                    0: {
                        ro_min: 6,
                        ro_max: 10000,
                        aro_min: 0,
                        aro_max: 100
                    },
                    1: {
                        ro_min: 0,
                        ro_max: 5,
                        aro_min: 0,
                        aro_max: 10000
                    },
                    2: {
                        ro_min: 6,
                        ro_max: 10000,
                        aro_min: 100,
                        aro_max: 10000
                    },
                    3: {
                        ro_min: 6,
                        ro_max: 10000,
                        aro_min: 250,
                        aro_max: 10000
                    },
                    4: {
                        ro_min: 6,
                        ro_max: 10000,
                        aro_min: 350,
                        aro_max: 10000
                    }
                };
                this.setState({ shopRankConfig: shopRankConfigDefault, rankModal: value });
            }
        } else {
            this.setState({ rankModal: value });
        }
    }

    /////////////////////

    handleDefaultRank() {
        var shopRankConfigDefault = {
            dts: new Date().getTime(),
            0: {
                ro_min: 6,
                ro_max: 10000,
                aro_min: 0,
                aro_max: 100
            },
            1: {
                ro_min: 0,
                ro_max: 5,
                aro_min: 0,
                aro_max: 10000
            },
            2: {
                ro_min: 6,
                ro_max: 10000,
                aro_min: 100,
                aro_max: 10000
            },
            3: {
                ro_min: 6,
                ro_max: 10000,
                aro_min: 250,
                aro_max: 10000
            },
            4: {
                ro_min: 6,
                ro_max: 10000,
                aro_min: 350,
                aro_max: 10000
            }
        };

        this.setState({ shopRankConfig: shopRankConfigDefault });
    }

    /////////////////////

    handleRankChange(rank, elem, values) {
        var tmp = { ...this.state.shopRankConfig };
        if (elem === 'ro') {
            tmp[rank].ro_min = values[0];
            if (values[1] === 20) {
                tmp[rank].ro_max = 10000;
            } else {
                tmp[rank].ro_max = values[1];
            }
        } else if (elem === 'aro') {
            tmp[rank].aro_min = values[0];
            if (values[1] === 1000) {
                tmp[rank].aro_max = 10000;
            } else {
                tmp[rank].aro_max = values[1];
            }
        }
        this.setState({ shopRankConfig: tmp });
    }

    /////////////////////

    updateShopRankConfig() {
        this.props.auth0.getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + 'api/companies/rankConfig',
                { shop_id: this.props.shop._id, rankConfig: this.state.shopRankConfig },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                this.getCustomerRank();
                this.handleRankModal(false);
                toast.success("Shop Rank Config Updated");
            }).catch((error) => {
                toast.error(error.response.data);
            });
        }).catch((token_error) => {
            console.log(token_error);
        });
    }

    /////////////////////

    displayCustomer() {
        var src = 'images/customer_1.png';
        var tooltip = 'Developing Customer';
        if (this.state.customerRank !== null) {
            if (this.state.customerRank.rank === 0) {
                tooltip = "Don't Book";
                src = 'images/customer_0.png';
            } else if (this.state.customerRank.rank === 4) {
                tooltip = "Best Customer";
                src = 'images/customer_4.png';
            }
        } else {
            if (
                this.state.shopRankConfig[4].ro_min <= this.state.customer.total_ros && this.state.customer.total_ros <= this.state.shopRankConfig[4].ro_max
                &&
                this.state.shopRankConfig[4].aro_min <= parseInt(this.state.customer.total_sales / this.state.customer.total_ros) && parseInt(this.state.customer.total_sales / this.state.customer.total_ros) <= this.state.shopRankConfig[4].aro_max
            ) {
                src = 'images/customer_4.png';
                tooltip = "Best Customer";
            } else if (
                this.state.shopRankConfig[3].ro_min <= this.state.customer.total_ros && this.state.customer.total_ros <= this.state.shopRankConfig[3].ro_max
                &&
                this.state.shopRankConfig[3].aro_min <= parseInt(this.state.customer.total_sales / this.state.customer.total_ros) && parseInt(this.state.customer.total_sales / this.state.customer.total_ros) <= this.state.shopRankConfig[3].aro_max
            ) {
                src = 'images/customer_3.png';
                tooltip = "Better Customer";
            } else if (
                this.state.shopRankConfig[2].ro_min <= this.state.customer.total_ros && this.state.customer.total_ros <= this.state.shopRankConfig[2].ro_max
                &&
                this.state.shopRankConfig[2].aro_min <= parseInt(this.state.customer.total_sales / this.state.customer.total_ros) && parseInt(this.state.customer.total_sales / this.state.customer.total_ros) <= this.state.shopRankConfig[2].aro_max
            ) {
                src = 'images/customer_2.png';
                tooltip = "Good Customer";
            } else if (
                this.state.shopRankConfig[1].ro_min <= this.state.customer.total_ros && this.state.customer.total_ros <= this.state.shopRankConfig[1].ro_max
                &&
                this.state.shopRankConfig[1].aro_min <= parseInt(this.state.customer.total_sales / this.state.customer.total_ros) && parseInt(this.state.customer.total_sales / this.state.customer.total_ros) <= this.state.shopRankConfig[1].aro_max
            ) {
                src = 'images/customer_1.png';
                tooltip = "Developing Customer";
            } else if (
                this.state.shopRankConfig[0].ro_min <= this.state.customer.total_ros && this.state.customer.total_ros <= this.state.shopRankConfig[0].ro_max
                &&
                this.state.shopRankConfig[0].aro_min <= parseInt(this.state.customer.total_sales / this.state.customer.total_ros) && parseInt(this.state.customer.total_sales / this.state.customer.total_ros) <= this.state.shopRankConfig[0].aro_max
            ) {
                src = 'images/customer_0.png';
                tooltip = "Don't Book";
            }
        }

        return (
            <Box flexGrow={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <Card elevation={3} sx={{ width: '100%', height: '100%' }}>
                            <CardContent>
                                <Grid container xs={12}>
                                    <Grid item xs={5} textAlign='center'>
                                        <Tooltip title={tooltip} arrow placement='top'>
                                            <img src={src} alt='customer_type' style={{ height: '110px' }} />
                                        </Tooltip>
                                        {
                                            this.state.customerRank !== null
                                                ?
                                                this.state.customerRank.rank === 0
                                                    ?
                                                    <Stack direction="row" spacing={0} sx={{ marginTop: 1, marginBottom: 1 }} justifyContent='center'>
                                                        <Tooltip title={"Marked as Don't Book"} arrow placement='bottom'>
                                                            <IconButton color="error" onClick={() => { this.removeCustomerRank(); }}>
                                                                <ThumbDownRoundedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title={"Configure Icons"} arrow placement='bottom'>
                                                            <IconButton onClick={() => { this.handleRankModal(true); }} color='info'>
                                                                <SettingsIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title={'Mark as a Best Customer'} arrow placement='bottom'>
                                                            <IconButton onClick={() => { this.markCustomer(4); }}>
                                                                <ThumbUpRoundedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                    :
                                                    this.state.customerRank.rank === 4
                                                        ?
                                                        <Stack direction="row" spacing={0} sx={{ marginTop: 1, marginBottom: 1 }} justifyContent='center'>
                                                            <Tooltip title={"Mark as Don't Book"} arrow placement='bottom'>
                                                                <IconButton onClick={() => { this.markCustomer(0); }}>
                                                                    <ThumbDownRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={"Configure Icons"} arrow placement='bottom'>
                                                                <IconButton onClick={() => { this.handleRankModal(true); }} color='info'>
                                                                    <SettingsIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={'Marked as a Best Customer'} arrow placement='bottom'>
                                                                <IconButton color="success" onClick={() => { this.removeCustomerRank(); }}>
                                                                    <ThumbUpRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                        :
                                                        <Stack direction="row" spacing={0} sx={{ marginTop: 1, marginBottom: 1 }} justifyContent='center'>
                                                            <Tooltip title={"Mark as Don't Book"} arrow placement='bottom'>
                                                                <IconButton onClick={() => { this.markCustomer(0); }}>
                                                                    <ThumbDownRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={"Configure Icons"} arrow placement='bottom'>
                                                                <IconButton onClick={() => { this.handleRankModal(true); }} color='info'>
                                                                    <SettingsIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={'Mark as a Best Customer'} arrow placement='bottom'>
                                                                <IconButton onClick={() => { this.markCustomer(4); }}>
                                                                    <ThumbUpRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                :
                                                <Stack direction="row" spacing={0} sx={{ marginTop: 1, marginBottom: 1 }} justifyContent='center'>
                                                    <Tooltip title={"Mark as Don't Book"} arrow placement='bottom'>
                                                        <IconButton onClick={() => { this.markCustomer(0); }}>
                                                            <ThumbDownRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"Configure Icons"} arrow placement='bottom'>
                                                        <IconButton onClick={() => { this.handleRankModal(true); }} color='info'>
                                                            <SettingsIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'Mark as a Best Customer'} arrow placement='bottom'>
                                                        <IconButton onClick={() => { this.markCustomer(4); }}>
                                                            <ThumbUpRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                        }
                                    </Grid>
                                    <Grid item xs={7} sx={{ paddingLeft: 1 }}>
                                        <Typography variant='caption' gutterBottom>Name</Typography>
                                        <Typography variant='h6' gutterBottom>
                                            {
                                                this.props.shop.system === 'tekmetric'
                                                    ?
                                                    <>
                                                        <Link href={`https://shop.tekmetric.com/admin/shop/${this.state.customer.shop_id}/customers/${this.state.customer.customer_id}`} target='_blank'>
                                                            {this.state.customer.first_name}<br />{this.state.customer.last_name}
                                                        </Link>
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.customer.first_name}<br />{this.state.customer.last_name}
                                                    </>
                                            }
                                            <br />
                                            <Typography variant='caption'>
                                                {this.state.customer.email ? <Link href={`mailto:${this.state.customer.email}`}>{this.state.customer.email}</Link> : <></>}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider flexItem sx={{ mb: 1 }} />
                                <Grid container xs={12}>
                                    <Grid item xs={7}>
                                        <Typography variant='caption' gutterBottom>Address</Typography>
                                        <Typography variant='body2' gutterBottom>
                                            {
                                                this.state.customer['address_1'] !== '' && this.state.customer['address_1'] !== null
                                                    ?
                                                    <>
                                                        {this.state.customer['address_1']}<br />
                                                        {this.state.customer['address_2']}<br />
                                                        {this.state.customer['city']}, {this.state.customer['state']} {this.state.customer['zip']}
                                                    </>
                                                    :
                                                    <>
                                                        No Address
                                                    </>
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant='caption' gutterBottom>Phone(s)</Typography>
                                        <Typography variant='body2' gutterBottom>
                                            {
                                                this.state.customer.phone.length > 0
                                                    ?
                                                    <>
                                                        {this.state.customer.phone.map((p) => {
                                                            return (<>{p.phone}<br /></>);
                                                        })}
                                                    </>
                                                    :
                                                    <>
                                                        No Phone Numbers
                                                    </>
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider flexItem sx={{ mb: 1 }} />
                                <Grid container xs={12}>
                                    <Grid item xs={6}>
                                        <Typography variant='body2'>
                                            <Typography variant='caption'>First Visit</Typography> {this.state.customer.first_visit ? dayjs(this.state.customer.first_visit).format('MM/DD/YYYY') : 'N/A'}<br />
                                            <Typography variant='caption'>Last Visit</Typography> {this.state.customer.last_visit ? dayjs(this.state.customer.last_visit).format('MM/DD/YYYY') : 'N/A'}<br />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip title={this.state.customer.notes} arrow>
                                            <Chip label="Customer Notes" variant='outlined' color={this.state.customer.notes !== '' && this.state.customer.notes !== null ? 'primary' : 'default'} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item container xs={12} lg={8} spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Card elevation={3} sx={{ width: '100%' }}>
                                <CardContent>
                                    <Grid flexGrow={1} container spacing={2}>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }} textAlign='right'>
                                            <Typography variant='h6' >RO's</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }} textAlign='left'>
                                            <Chip sx={{ fontSize: '20px', height: '30px' }} label={this.state.customer.total_ros} color="primary" />
                                        </Grid>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }} textAlign='right'>
                                            <Typography variant='h6' >Sales</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }} textAlign='left'>
                                            <Chip sx={{ fontSize: '20px', height: '30px' }} label={'$' + parseInt(this.state.customer.total_sales)} color="primary" />
                                        </Grid>
                                        <Grid item xs={6} textAlign='right'>
                                            <Typography variant='h6' >Profit</Typography>
                                        </Grid>
                                        <Grid item xs={6} textAlign='left'>
                                            <Chip sx={{ fontSize: '20px', height: '30px' }} label={'$' + parseInt(this.state.customer.total_profit)} color="primary" />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Card elevation={3} sx={{ width: '100%' }}>
                                <CardContent>
                                    <Grid flexGrow={1} container spacing={2}>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }} textAlign='right'>
                                            <Typography variant='h6' >Close Ratio</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }} textAlign='left'>
                                            <Chip sx={{ fontSize: '20px', height: '30px' }} label={parseInt((this.state.customer.approved_labor_hours / (this.state.customer.declined_labor_hours + this.state.customer.approved_labor_hours)) * 100) + '%'} color="primary" />
                                        </Grid>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }} textAlign='right'>
                                            <Typography variant='h6' >ARO Sales</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }} textAlign='left'>
                                            <Chip sx={{ fontSize: '20px', height: '30px' }} label={'$' + parseInt(this.state.customer.total_sales / this.state.customer.total_ros)} color="primary" />
                                        </Grid>
                                        <Grid item xs={6} textAlign='right'>
                                            <Typography variant='h6' >ARO Profit</Typography>
                                        </Grid>
                                        <Grid item xs={6} textAlign='left'>
                                            <Chip sx={{ fontSize: '20px', height: '30px' }} label={'$' + parseInt(this.state.customer.total_profit / this.state.customer.total_ros)} color="primary" />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card elevation={3} sx={{ width: '100%' }}>
                                <CardContent>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Select a Vehicle</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.vehicle ? this.state.vehicle.vehicle_id : null}
                                            label="Select a Vehicle"
                                            onChange={(e, data) => { this.handleVehicleChange(data.props.value) }}
                                        >
                                            {
                                                this.state.vehicles.map((vehicle) => {
                                                    return <MenuItem value={vehicle.vehicle_id}>{vehicle.license_plate} : {vehicle.year} {vehicle.make} {vehicle.model}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    /////////////////////

    displayVehicle() {
        var columns = [
            { field: 'col1', headerName: 'Date Posted', flex: .5, minWidth: 100 },
            {
                field: 'col2', headerName: 'RO #', flex: .5, minWidth: 100,
                renderCell: (params) => {
                    if (this.props.shop.system === 'tekmetric') {
                        return (
                            <Link href={`https://shop.tekmetric.com/admin/shop/${params.row.col2.shop}/repair-orders/${params.row.col2.ro_id}/estimate`} target='_blank'>
                                {params.row.col2.ro}
                            </Link>
                        );
                    } else {
                        return (
                            <>{params.row.col2.ro}</>
                        );
                    }
                },
                valueGetter: (params) => {
                    return params.value.ro;
                },
                sortComparator: (v1, v2) => { return v1 - v2; }
            },
            { field: 'col3', headerName: 'Mileage', flex: .5, minWidth: 100 },
            { field: 'col4', headerName: 'Job', flex: 2.5, minWidth: 200 },
            {
                field: 'col5', headerName: 'Total', flex: .5, minWidth: 100,
                renderCell: (params) => {
                    return (
                        <>${params.row.col5.total.toFixed(2)}</>
                    );
                },
                valueGetter: (params) => {
                    return Number(params.value.total.toFixed(2));
                },
                sortComparator: (v1, v2) => { return v1 - v2; }
            },
            {
                field: 'col6', headerName: 'Status', flex: .5, minWidth: 100,
                renderCell: (params) => {
                    return (
                        <>{params.value === 'Approved' ? <Chip label='Approved' color='success' /> : <Chip label='Declined' color='error' />}</>
                    );
                },
                valueGetter: (params) => {
                    if (params.value.authorized) {
                        return "Approved";
                    } else {
                        return "Declined";
                    }
                },
                sortComparator: (v1, v2) => { return v1.localeCompare(v2); }
            }
        ];

        var rows = [];
        this.state.jobs.forEach((job, i) => {
            if ((this.state.onlyDeclined && !job.authorized) || !this.state.onlyDeclined) {
                rows.push({
                    id: i,
                    col1: dayjs(job.ro_posted_date).format('MM/DD/YYYY'),
                    col2: { ro: job.repair_order_number, shop: job.shop_id, ro_id: job.ro_id },
                    col3: job.miles,
                    col4: job.job_name,
                    col5: { total: job.job_sales_minus_discounts },
                    col6: { authorized: job.authorized }
                });
            }
        });

        var gridHeight = 600;
        if (rows.length < 8) {
            gridHeight = rows.length * 52;
            gridHeight += 160;
        }

        return (
            <Box flexGrow={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card elevation={3} sx={{ width: '100%' }}>
                            <CardHeader
                                avatar={<Avatar sx={{ bgcolor: blue[500] }} aria-label="vehicle"><CarCrashRoundedIcon /></Avatar>}
                                title='Vehicle Data'
                            />
                            <Divider flexItem />
                            <CardContent>
                                <Grid container xs={12}>
                                    <Grid item xs={12} md={6} lg={3} textAlign='center'>
                                        <Typography variant='caption' gutterBottom>VIN</Typography>
                                        <Typography variation='body2' gutterBottom>{this.state.vehicle.vin}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} textAlign='center'>
                                        <Typography variant='caption' gutterBottom>Make</Typography>
                                        <Typography variation='body2' gutterBottom>{this.state.vehicle.make}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} textAlign='center'>
                                        <Typography variant='caption' gutterBottom>Model</Typography>
                                        <Typography variation='body2' gutterBottom>{this.state.vehicle.model} {this.state.vehicle.sub_model}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} textAlign='center'>
                                        <Typography variant='caption' gutterBottom>License Plate</Typography>
                                        <Typography variation='body2' gutterBottom>{this.state.vehicle.license_plate ? this.state.vehicle.license_plate : 'No Plate Found'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} textAlign='center'>
                                        <Typography variant='caption' gutterBottom>Engine</Typography>
                                        <Typography variation='body2' gutterBottom>{this.state.vehicle.engine ? this.state.vehicle.engine : 'No Engine Found'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} textAlign='center'>
                                        <Typography variant='caption' gutterBottom>First Visit</Typography>
                                        <Typography variation='body2' gutterBottom>{dayjs(this.state.vehicle.history[0].first_vehicle_visit).format('MM/DD/YYYY')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} textAlign='center'>
                                        <Typography variant='caption' gutterBottom>Last Visit</Typography>
                                        <Typography variation='body2' gutterBottom>{dayjs(this.state.vehicle.history[0].last_vehicle_visit).format('MM/DD/YYYY')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={3} textAlign='center'>
                                        <Typography variant='caption' gutterBottom>Latest Mileage</Typography>
                                        <Typography variation='body2' gutterBottom>{this.state.vehicle.history[0].latest_vehicle_service_mileage ? this.state.vehicle.history[0].latest_vehicle_service_mileage : 'No Mileage Found'}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card elevation={3} sx={{ width: '100%' }}>
                            <CardHeader
                                avatar={<Avatar sx={{ bgcolor: blue[500] }} aria-label="service history"><HomeRepairServiceRoundedIcon /></Avatar>}
                                title='Service History Mileages'
                            />
                            <Divider flexItem />
                            <CardContent>
                                <Grid container xs={12}>
                                    {
                                        this.state.vehicle.history[0].category !== null
                                            ?
                                            this.state.vehicle.history.map((h) => {
                                                if (h.category && h.category !== 'NA') {
                                                    return (
                                                        <Grid item xs={3} md={2} textAlign='center'>
                                                            <Typography variant='caption' gutterBottom>{h.category}</Typography><br />
                                                            <Tooltip title={h.last_service_date ? 'Last Service Date: ' + dayjs(h.last_service_date).format('MM/DD/YYYY') : h.last_service_date} arrow>
                                                                <Chip
                                                                    label={h.last_service_mileage ? h.last_service_mileage : 'No Mileage Found'}
                                                                    variant='outlined'
                                                                    color={h.last_service_date ? 'primary' : 'default'}
                                                                />
                                                            </Tooltip>
                                                        </Grid>
                                                    );
                                                }
                                            })
                                            :
                                            <Grid item xs={12} textAlign='center'>
                                                <Typography variation='body2' gutterBottom>No Categorized Service History Found</Typography>
                                            </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card elevation={3} sx={{ width: '100%' }}>
                            <CardHeader
                                avatar={<Avatar sx={{ bgcolor: blue[500] }} aria-label="declined work"><WorkHistoryRoundedIcon /></Avatar>}
                                title={'Vehicle Job History'}
                                action={
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.state.onlyDeclined}
                                                onChange={(e, data) => { this.toggleOnlyDeclined(); }}
                                            />
                                        }
                                        label="Only Declined"
                                    />
                                }
                            />
                            {
                                rows.length > 0
                                    ?
                                    <div style={{ height: gridHeight, width: '100%' }}>
                                        <DataGridPro
                                            rows={rows}
                                            columns={columns}
                                            components={{ Toolbar: GridToolbar }}
                                            componentsProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                    quickFilterProps: { debounceMs: 500 },
                                                }
                                            }}
                                        />
                                    </div>
                                    :
                                    <>
                                        <Divider flexItem />
                                        <CardContent>
                                            <Typography variation='body2' gutterBottom>No Job History Found</Typography>
                                        </CardContent>
                                    </>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    /////////////////////

    componentDidMount() {
        this.setShopConfig();
    }

    /////////////////////

    componentDidUpdate(prevProps) {
        if (prevProps.shop._id !== this.props.shop._id) {
            this.setShopConfig();
        }
    }

    /////////////////////

    render() {
        if (this.props.auth0.isAuthenticated) {
            return (
                <>
                    <Dialog fullWidth={true} maxWidth='md' open={this.state.rankModal} onClose={() => { this.handleRankModal(false); }}>
                        <DialogTitle>{this.props.shop.name} Rank Config</DialogTitle>
                        <DialogContent sx={{ padding: 5 }}>
                            <Grid container spacing={4} textAlign='center'>
                                <Grid item xs={4}>
                                    <Typography variant='caption'>Rank = RO Count AND ARO $ Amount<br />Values are Inclusive</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant='h7'>RO Range (RO Count)</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant='h7'>ARO Range ($ Amount)</Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <img src={'images/customer_0.png'} alt='customer_type' style={{ height: '30px' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='h7'>Don't Book</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[0].ro_min, this.state.shopRankConfig[0].ro_max]}
                                            onChange={(e) => { this.handleRankChange(0, 'ro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            min={0}
                                            max={20}
                                        />
                                        {
                                            this.state.shopRankConfig[0].ro_max >= 20
                                                ?
                                                <Typography variant='caption'>{this.state.shopRankConfig[0].ro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>{this.state.shopRankConfig[0].ro_min} - {this.state.shopRankConfig[0].ro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[0].aro_min, this.state.shopRankConfig[0].aro_max]}
                                            onChange={(e) => { this.handleRankChange(0, 'aro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={10}
                                            min={0}
                                            max={1000}
                                        />
                                        {
                                            this.state.shopRankConfig[0].aro_max >= 1000
                                                ?
                                                <Typography variant='caption'>${this.state.shopRankConfig[0].aro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>${this.state.shopRankConfig[0].aro_min} - ${this.state.shopRankConfig[0].aro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={1}>
                                    <img src={'images/customer_1.png'} alt='customer_type' style={{ height: '30px' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='h7'>Developing Customer</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[1].ro_min, this.state.shopRankConfig[1].ro_max]}
                                            onChange={(e) => { this.handleRankChange(1, 'ro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            min={0}
                                            max={20}
                                        />
                                        {
                                            this.state.shopRankConfig[1].ro_max >= 20
                                                ?
                                                <Typography variant='caption'>{this.state.shopRankConfig[1].ro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>{this.state.shopRankConfig[1].ro_min} - {this.state.shopRankConfig[1].ro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[1].aro_min, this.state.shopRankConfig[1].aro_max]}
                                            onChange={(e) => { this.handleRankChange(1, 'aro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={10}
                                            min={0}
                                            max={1000}
                                        />
                                        {
                                            this.state.shopRankConfig[1].aro_max >= 1000
                                                ?
                                                <Typography variant='caption'>${this.state.shopRankConfig[1].aro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>${this.state.shopRankConfig[1].aro_min} - ${this.state.shopRankConfig[1].aro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={1}>
                                    <img src={'images/customer_2.png'} alt='customer_type' style={{ height: '30px' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='h7'>Good Customer</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[2].ro_min, this.state.shopRankConfig[2].ro_max]}
                                            onChange={(e) => { this.handleRankChange(2, 'ro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            min={0}
                                            max={20}
                                        />
                                        {
                                            this.state.shopRankConfig[2].ro_max >= 20
                                                ?
                                                <Typography variant='caption'>{this.state.shopRankConfig[2].ro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>{this.state.shopRankConfig[2].ro_min} - {this.state.shopRankConfig[2].ro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[2].aro_min, this.state.shopRankConfig[2].aro_max]}
                                            onChange={(e) => { this.handleRankChange(2, 'aro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={10}
                                            min={0}
                                            max={1000}
                                        />
                                        {
                                            this.state.shopRankConfig[2].aro_max >= 1000
                                                ?
                                                <Typography variant='caption'>${this.state.shopRankConfig[2].aro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>${this.state.shopRankConfig[2].aro_min} - ${this.state.shopRankConfig[2].aro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={1}>
                                    <img src={'images/customer_3.png'} alt='customer_type' style={{ height: '30px' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='h7'>Better Customer</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[3].ro_min, this.state.shopRankConfig[3].ro_max]}
                                            onChange={(e) => { this.handleRankChange(3, 'ro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            min={0}
                                            max={20}
                                        />
                                        {
                                            this.state.shopRankConfig[3].ro_max >= 20
                                                ?
                                                <Typography variant='caption'>{this.state.shopRankConfig[3].ro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>{this.state.shopRankConfig[3].ro_min} - {this.state.shopRankConfig[3].ro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[3].aro_min, this.state.shopRankConfig[3].aro_max]}
                                            onChange={(e) => { this.handleRankChange(3, 'aro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={10}
                                            min={0}
                                            max={1000}
                                        />
                                        {
                                            this.state.shopRankConfig[3].aro_max >= 1000
                                                ?
                                                <Typography variant='caption'>${this.state.shopRankConfig[3].aro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>${this.state.shopRankConfig[3].aro_min} - ${this.state.shopRankConfig[3].aro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={1}>
                                    <img src={'images/customer_4.png'} alt='customer_type' style={{ height: '30px' }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant='h7'>Best Customer</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[4].ro_min, this.state.shopRankConfig[4].ro_max]}
                                            onChange={(e) => { this.handleRankChange(4, 'ro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            min={0}
                                            max={20}
                                        />
                                        {
                                            this.state.shopRankConfig[4].ro_max >= 20
                                                ?
                                                <Typography variant='caption'>{this.state.shopRankConfig[4].ro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>{this.state.shopRankConfig[4].ro_min} - {this.state.shopRankConfig[4].ro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Slider
                                            size='small'
                                            value={[this.state.shopRankConfig[4].aro_min, this.state.shopRankConfig[4].aro_max]}
                                            onChange={(e) => { this.handleRankChange(4, 'aro', e.target.value); }}
                                            valueLabelDisplay="auto"
                                            step={10}
                                            min={0}
                                            max={1000}
                                        />
                                        {
                                            this.state.shopRankConfig[4].aro_max >= 1000
                                                ?
                                                <Typography variant='caption'>${this.state.shopRankConfig[4].aro_min}+</Typography>
                                                :
                                                <Typography variant='caption'>${this.state.shopRankConfig[4].aro_min} - ${this.state.shopRankConfig[4].aro_max}</Typography>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => { this.handleDefaultRank(); }}>
                                Set Default
                            </Button>
                            <div style={{ flex: '1 0 0' }} />
                            <Button variant="contained" color='error' onClick={() => { this.handleRankModal(false); }}>
                                Cancel
                            </Button>
                            <Button variant="contained" endIcon={<AddCircleRoundedIcon />} color='success' onClick={() => { this.updateShopRankConfig(); }}>
                                Save Config
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Stack flexGrow={1} direction='column' spacing={4}>
                        <Box flexGrow={1}>
                            <Paper elevation={5} sx={{ p: 2 }}>
                                <Typography variant='h6' gutterBottom color='primary'>Service Advisor Assistant</Typography>
                                <Divider flexItem sx={{ mb: 2 }} />
                                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                                    <Box flexGrow={1}>
                                        <Autocomplete
                                            disabled={this.state.customerLoading}
                                            options={this.state.autoNameOptions}
                                            getOptionLabel={(option) => option.name}
                                            filterOptions={(x) => x} // disable filtering on client
                                            loading={this.state.autoNameLoading}
                                            onInputChange={(e, val) => { this.debouncedNameSearch(val); }}
                                            onChange={(e, val) => { this.getCustomer(val.customer_id); }}
                                            disableClearable={true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Customer Name"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {this.state.autoNameLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            size="small"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Autocomplete
                                            disabled={this.state.customerLoading}
                                            options={this.state.autoPhoneOptions}
                                            getOptionLabel={(option) => option.phone}
                                            filterOptions={(x) => x} // disable filtering on client
                                            loading={this.state.autoPhoneLoading}
                                            onInputChange={(e, val) => { this.debouncedPhoneSearch(val); }}
                                            onChange={(e, val) => { this.getCustomer(val.customer_id); }}
                                            disableClearable={true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Phone Number"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {this.state.autoPhoneLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            size="small"
                                        />
                                    </Box>
                                </Stack>
                            </Paper >
                        </Box>
                        <Box flexGrow={1}>
                            {
                                this.state.customerLoading
                                    ?
                                    <Grid container>
                                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                            <CircularProgress size={60} thickness={4} />
                                        </Grid>
                                    </Grid>
                                    :
                                    this.state.customer
                                        ?
                                        <>
                                            {this.displayCustomer()}
                                            <br />
                                            {
                                                this.state.vehicleLoading
                                                    ?
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                                            <CircularProgress size={60} thickness={4} />
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    this.state.vehicle
                                                        ?
                                                        this.displayVehicle()
                                                        :
                                                        <></>
                                            }
                                        </>
                                        :
                                        <></>
                            }
                        </Box>
                    </Stack>
                </>
            );
        } else {
            this.props.auth0.loginWithRedirect();
        }
    }
}

export default withAuth0(CustomerSearch);
